@import 'styles/index';

.coursesPage {
  display: flex;
  position: relative;
  flex-direction: column;

  width: 100%;
  max-width: set-calc(1328);
  margin: set-calc(32) auto set-calc(40);

  @include set-media(md) {
    margin: set-calc(24) auto;
  }
}

.coursesPage__content {
  display: flex;
  flex-direction: column;
  width: 100%;

  gap: set-calc(80);

  @include set-media(md) {
    gap: set-calc(40);
  }
}

.section {
  position: relative;

  display: block;
}
