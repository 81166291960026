.list-empty {
  display: flex;
  flex-direction: column;
  grid-column: 5 / 9;
  align-items: center;
  padding: set-calc(80) 0;

  text-align: center;

  &-title {
    padding-bottom: set-calc(24);
  }

  &-text {
    padding-bottom: set-calc(40);
  }

  &-button {
    width: set-calc(294);
    height: set-calc(48);

    color: var(--color-content-inverted-primary);

    background-color: var(--primary-color);
    border-radius: set-calc($border-radius-20);
  }
}