@import 'styles/index';

.header {
  @include header;
}

.animationWrapper {
  position: relative;

  overflow: hidden;
  display: flex;
  gap: set-calc(4);

  width: set-calc(400, true, 100%);
  left: set-calc(-200);

  @include set-media(md) {
    width: set-calc(8, true, 100%);
    left: set-calc(-8);

    gap: set-calc(2);
  }
}

.group {
  display: flex;
  gap: set-calc(4);

  animation: 120s linear infinite marqueeAnimation;

  @include set-media(md) {
    position: relative;
    
    display: flex;
    flex-flow: row nowrap;
    gap: set-calc(2);
    overflow-x: scroll;

    padding: 0 set-calc(8);

    animation: none;
  }
}