@import 'styles/index';

.coursePage {
  @include is-grid-12;

  @include set-media(md) {
    gap: set-calc(40);
  }
}

.main {
  grid-column: span 7;

  display: flex;
  flex-direction: column;
  gap: set-calc(40);

  @include set-media(md) {
    width: 100%;
    gap: set-calc(32);
  }
}

.asideWrapper {
  grid-column: 9 / -1;

  @include set-media(md) {
    width: 100%;
  }
}

.aside {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: set-calc(calc(61));
  
  display: flex;
  flex-direction: column;
  gap: set-calc(40);

  @include set-media(md) {
    position: relative;
    top: 0;
  }
}

.asideSection {
  display: flex;
  flex-direction: column;
}

.section__title {
  @include section-title;
}

.chipsSmall {
  display: flex;
  flex-wrap: wrap;

  gap: set-calc(8);
}

.chipSmall {
  @include btn;
  @include chips-40;
  @include chips-outline;
}

.level {
  position: relative;

  display: flex;
  flex-direction: column;
}

.level__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header__meta {
  display: flex;
  gap: set-calc(16);

  padding: 0 set-calc(8);

  &__number {
    display: flex;
    padding: set-calc(8) 0;

    color: var(--color-content-tertiary);
    text-align: center;

    @include font-14-ui;
  }

  &__state {
    display: flex;
    padding: set-calc(8);

    border-radius: set-calc(8);
    background: var(--color-background-glassy-a);

    color: var(--color-accent-success);
    text-align: center;

    @include font-14-ui;
  }

  &__type {
    display: flex;
    padding: set-calc(8);

    border-radius: set-calc(8);
    background: var(--color-background-glassy-a);

    color: var(--color-background-glassy-d);
    text-align: center;

    @include font-14-ui;
  }
}

.header__content {
  display: flex;
  flex-direction: column;

  padding: set-calc(8) 0 set-calc(24);

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-bottom: set-calc(8);

    .level__title {
      display: flex;
      padding: 0 set-calc(8);
      flex: 1 0 0;

      @include font-28-header;
      font-family: $font-family-medium;
    }

    .level__arrow {
      @include icon-button-40;
      @include icon-button-invisible;

      width: set-calc(40);

      transition: transform $d-hover ease-in-out;

      &:hover {
        background: transparent;
        cursor: pointer;
      }

      @include set-media(md) {
        @include icon-button-32;
        width: set-calc(32);

        color: var(--color-content-tertiary);
      }
    }

    .arrow_expended {
      transform: rotate(180deg);
    }
  }

  &__description {
    display: flex;
    flex-direction: column;

    padding: 0 set-calc(8);

    color: var(--color-content-tertiary);
    @include font-18-paragraph;
  }

  @include set-media(md) {
    padding: set-calc(4) 0 set-calc(16);
  }
}

.list {
  height: 0;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  width: 100%;
  gap: set-calc(8);

  padding-bottom: 0;

  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;

  transition-property: height, transform, margin;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.4,0.4,0.2,1);
}

.list_expended {
  overflow: visible;
  padding-bottom: set-calc(24);

  transform: translate3d(0px, -8px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);

  transition-property: height, transform, margin;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.4,0.4,0.2,1);
}

.itemWrapper {
  &[data-locked="true"] {
    .item {
      background-color: var(--color-background-glassy-a);
    }
  }

  &:not([data-locked="true"]) {
    &:hover {
      .item {
        transform: scale(1);
        border: 1px solid var(--border);
        box-shadow: $shadow-medium-default;
      }

      .featured {
        transform: scale(1);
        box-shadow: $shadow-medium-default;
      }
    }
  
    @include set-media(md) {
      &:hover {

        .item {
          box-shadow: none!important;
          transform: scale(1,1)!important;
        }
      }
    }
  }
}

.item {
  @include btn;
  position: relative;

  justify-content: space-between;
  align-items: center;
  width: 100%;

  padding: set-calc(16) set-calc(16) set-calc(16) 0;

  border: 1px solid var(--color-card-border);
  border-radius: set-calc($border-radius-20);
  background: var(--color-card);

  text-align: start;

  &:disabled {
    color: var(--color-content-primary);
  }

  @include set-media(md) {
    width: 100%;
    gap: set-calc(16);
    padding: set-calc(16);
  }
}

.item__content {
  position: relative;

  display: flex;
  align-items: center;
  gap: set-calc(4);
  width: 100%;
}

.item__title {
  @include font-16-paragraph;
  font-family: $font-family-medium;
  color: var(--color-content-primary);
}

.item__image {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: set-calc(6);
  padding-left: set-calc(12);

  svg {
    width: set-calc(40);
    aspect-ratio: auto 1;
  }
}
.featured:after {
  content: "";
  position: absolute;
  top: set-calc(-2);
  bottom: set-calc(-2);
  left: set-calc(-2);
  right: set-calc(-2);
  border: 4px solid var(--primary-color);
  border-radius: inherit;
}

.featured {
  .itemPopover {
    opacity: 1;
    pointer-events: all;
    transform: translate3d(-50%, 0, 0) scale(1);
  }
}

.itemPopover {
  display: flex;
  flex-direction: column;
  position: absolute;

  top: auto;
  bottom: calc(100% - 6px);
  left: 50%;
  z-index: 3;

  color: $color-content-inverted-primary-light;
  @include font-16-ui;
  font-family: $font-family-medium;


  box-shadow: 0 0 32px var(--black-300-constant);
  background: var(--primary-color);
  opacity: 0;

  cursor: pointer;

  padding: set-calc(14) set-calc(14);
  width: auto;
  border-radius: set-calc($border-radius-12);

  animation: itemPopover 1.6s linear infinite;

  transform: translate3d(-50%, 0, 0) scale(.25);
  transition: opacity .2s cubic-bezier(.25,.01,.25,1) 0s, transform .2s cubic-bezier(.25,.01,.25,1) 0s;
}

.itemPopover::before {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 50%;

  width: set-calc(14);
  height: set-calc(14);
  content: "";
  border: 2px solid var(--primary-color);
  border-radius: set-calc(3) 0;
  background: var(--primary-color);
  transform: translate3d(-50%, calc(50% - 1px), 0) rotate(45deg);
  box-sizing: border-box;
}

.itemStatus {
  @include icon-size(24);

  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  color: var(--color-content-inverted-primary);
  background-color: var(--color-background-glassy-c);
  padding: set-calc(6);
  border-radius: 50%;
}

.solved {
  background-color: var(--color-accent-success);
  color: var(--color-content-inverted-primary);
}

.locked {
  background-color: transparent;
  color: var(--color-content-tertiary);
}

.task {
  position: relative;
  margin-top: set-calc(8);
}