@use "sass:math";
$rem-base: 16px;
$font-family-light: Montserrat-Light, sans-serif;
$font-family-normal: Montserrat-Normal, sans-serif;
$font-family-medium: Montserrat-Medium, sans-serif;
$font-family-bold: Montserrat-Bold, sans-serif;
$font-family-article-light: Commissioner-Light, sans-serif;
$font-family-article: Commissioner, sans-serif;
$font-family-article-medium: Commissioner-Medium, sans-serif;
$font-family-article-bold: Commissioner-Bold, sans-serif;

$p-page-desk-l: 200;
$p-page-desk-m: 100;
$p-page-desk-s: 16;
$p-page-mob-l: 16;
$p-page-mob-m: 16;
$p-page-mob-s: 8;

$white: #fff;
$black: #000;

//color-palette-black
$color-palette-black-25: #fdfcfc;
$color-palette-black-50: #f5f5f5;
$color-palette-black-75: #f7f7f7;
$color-palette-black-100: #f0f0f0;
$color-palette-black-150: #eae8e8;
$color-palette-black-200: #e0e0e0;
$color-palette-black-300: #bfbfbf;
$color-palette-black-400: #999999;
$color-palette-black-500: #4C4C4C;
$color-palette-black-600: #2e2e2e;
$color-palette-black-700: #262626;
$color-palette-black-800: #1c1c1c;
$color-palette-black-900: #161616;
$color-palette-black-950: #121212;
$color-palette-black-1000: #000000;

//color-palette-black-a
$color-palette-black-15-a: rgba(0, 0, 0, 0.015);
$color-palette-black-25-a: rgba(0, 0, 0, 0.025);
$color-palette-black-50-a: rgba(0, 0, 0, 0.05);
$color-palette-black-100-a: rgba(0, 0, 0, 0.1);
$color-palette-black-200-a: rgba(0, 0, 0, 0.2);
$color-palette-black-300-a: rgba(0, 0, 0, 0.3);
$color-palette-black-500-a: rgba(0, 0, 0, 0.5);
$color-palette-black-600-a: rgba(0, 0, 0, 0.6);
$color-palette-black-700-a: rgba(0, 0, 0, 0.7);
$color-palette-black-800-a: rgba(0, 0, 0, 0.8);
$color-palette-black-900-a: rgba(0, 0, 0, 0.9);

//color-palette-white-a
$color-palette-white-25-a: rgba(0, 0, 0, 0.025);
$color-palette-white-50-a: rgba(255, 255, 255, 0.05);
$color-palette-white-100-a: rgba(255, 255, 255, 0.1);
$color-palette-white-200-a: rgba(255, 255, 255, 0.2);
$color-palette-white-300-a: rgba(255, 255, 255, 0.3);
$color-palette-white-500-a: rgba(255, 255, 255, 0.5);
$color-palette-white-700-a: rgba(255, 255, 255, 0.7);
$color-palette-white-800-a: rgba(255, 255, 255, 0.8);
$color-palette-white-900-a: rgba(255, 255, 255, 0.9);
$color-palette-white-1000: #ffffff;

//color-palette-primary
$color-palette-primary-25: #F1EAFF;
$color-palette-primary-50: #E0CFFF;
$color-palette-primary-100: #CFB8FF;
$color-palette-primary-200: #B595FF;
$color-palette-primary-300: #9F72FF;
$color-palette-primary-400: #8B5BFF;
$color-palette-primary-500: #7342FF;
$color-palette-primary-600: #5627FF;
$color-palette-primary-700: #5026E3;

//color
$color-pink: #e39bdf;
$color-violet: #855be1;
$color-green: #59c193;
$color-blue: #405df6;
$color-red: #eb4e27;
$color-yellow: #f8db46;

$color-rose: #f8d6fc;
$color-purple: #bfacf9;
$color-mint: #77d3a8;
$color-sky: #74aef2;
$color-peach: #f5bd84;
$color-dust: #ffec8f;

//color-highlight
$color-highlight-yellow: #F8DB46;
$color-highlight-gold: #F5CB36;

//theme-light
$primary-color-light: $color-palette-primary-600;
$primary-color-hover-light: $color-palette-primary-500;
$color-content-primary-light: $color-palette-black-700;
$color-content-secondary-light: $color-palette-black-500;
$color-content-tertiary-light: $color-palette-black-400;
$color-content-paragraph-primary-light: $color-palette-black-600;
$color-content-paragraph-secondary-light: $color-palette-black-500;
$color-content-inverted-primary-light: $color-palette-black-25;
$color-content-inverted-secondary-light: $color-palette-black-200;
$color-content-background-primary-light: $color-palette-black-800;
$color-content-background-secondary-light: $color-palette-black-600;
$color-content-background-tertiary-light: $color-palette-black-500;
$color-background-alternate-light: $color-palette-black-75;
$color-background-default-light: $color-palette-white-1000;
$color-background-glassy-a-light: $color-palette-black-25-a;
$color-background-glassy-b-light: $color-palette-black-50-a;
$color-background-glassy-c-light: $color-palette-black-100-a;
$color-background-glassy-d-light: $color-palette-black-200-a;
$color-background-glassy-inverted-a-light: $color-palette-white-50-a;
$color-background-glassy-inverted-b-light: $color-palette-white-100-a;
$color-background-glassy-inverted-c-light: $color-palette-white-200-a;
$color-background-glassy-inverted-d-light: $color-palette-white-300-a;
$color-background-overlay-light: $color-palette-black-500-a;
$color-background-btn-light: $color-palette-black-700;
$color-background-btn-hover-light: $color-palette-black-900;
$color-background-violet-light: $color-purple;
$color-background-pink-light: $color-rose;

$color-accent-alert-light: #F04438;
$color-accent-warning-light: #FDB022;
$color-accent-success-light: #12B76A;
$color-accent-info-light: #405DF6;
$color-accent-promo-light: $color-palette-primary-600;
$color-accent-promo-background-light: $color-yellow;

$color-card-border-light: transparent; //$color-palette-white-300-a;
$color-card-light: $color-palette-white-1000;
$color-card-glassy-light: rgb(#f5f5f5, 0.8);

$border-light: $color-palette-black-100-a;

//theme-dark
$primary-color-dark: $color-palette-primary-300;
$primary-color-hover-dark: $color-palette-primary-200;
$color-background-default-dark: $color-palette-black-800;
$color-background-alternate-dark: $color-palette-black-950;
$color-content-primary-dark: $color-palette-black-50;
$color-content-secondary-dark: $color-palette-black-300;
$color-content-tertiary-dark: $color-palette-black-400;
$color-content-paragraph-primary-dark: $color-palette-black-300;
$color-content-paragraph-secondary-dark: $color-palette-black-400;
$color-content-inverted-primary-dark:  $color-palette-black-900;
$color-content-inverted-secondary-dark: $color-palette-black-600;
$color-content-background-primary-dark: $color-palette-black-100;
$color-content-background-secondary-dark: $color-palette-black-200;
$color-content-background-tertiary-dark: $color-palette-black-400;
$color-background-glassy-a-dark: $color-palette-white-50-a;
$color-background-glassy-b-dark: $color-palette-white-100-a;
$color-background-glassy-c-dark: $color-palette-white-200-a;
$color-background-glassy-d-dark: $color-palette-white-300-a;
$color-background-glassy-inverted-a-dark: $color-palette-black-15-a;
$color-background-glassy-inverted-b-dark: $color-palette-black-25-a;
$color-background-glassy-inverted-c-dark: $color-palette-black-50-a;
$color-background-glassy-inverted-d-dark: $color-palette-black-100-a;
$color-background-overlay-dark: $color-palette-black-500-a;
$color-background-btn-dark: $color-palette-black-50;
$color-background-btn-hover-dark: $color-palette-black-200;
$color-background-violet-dark: $color-violet;
$color-background-pink-dark: $color-pink;

$color-accent-alert-dark: #F04438;
$color-accent-warning-dark: #FDB022;
$color-accent-success-dark: #36BA5F;
$color-accent-info-dark: #405DF6;
$color-accent-promo-dark: $color-yellow;
$color-accent-promo-background-dark: $color-palette-primary-600;

$color-card-border-dark: hsla(0, 0%, 100%, .1);
$color-card-dark: $color-palette-black-700;
$color-card-glassy-dark: $color-palette-black-800-a;

$border-dark: $color-palette-black-500;

//links
$link: var(--color-content-primary);
$link-visited: var(--color-content-secondary);

$browsers: ('-o-transition', '-moz-transition', '-ms-transition', '-webkit-transition', '');

$breakpoints: (
  xxs: 319.98px,
  xs: 374.98px,
  sm: 575.98px,
  md: 767.98px,
  lg: 991.98px,
  xl: 1199.98px,
);

//duration
$d-focus: .1s;
$d-hover: .25s;
$d-expansion: .5s;
$d-collapse: calc($d-expansion / 3);
$d-zoom: .3s;
$d-fade: 1s;
$d-slide: 5s;
$modal-animation-duration: 2s;

//shadow
$shadow-small-default: 0 0 1px 1px rgba(0, 0, 0, .02), 0 .3px .5px rgba(0, 0, 0, .1), 0 2px 4px rgba(0, 0, 0, .2);
$shadow-small-hovered: 0 0 1px 1px rgba(0, 0, 0, .02), 0 .4px .8px rgba(0, 0, 0, .1), 0 3px 6px rgba(0, 0, 0, .2);
$shadow-small-pressed: 0 0 1px 1px rgba(0, 0, 0, .02), 0 .3px .5px rgba(0, 0, 0, .1), 0 2px 4px rgba(0, 0, 0, .2);
$shadow-medium-default: 0px 6px 12px 0px rgba(36, 36, 36, 0.08);
$shadow-medium-hovered: 0px 16px 32px 0px rgba(36, 36, 36, 0.10);
$shadow-medium-pressed: 0px 4px 8px 0px rgba(36, 36, 36, 0.08);
$shadow-popup: 0px 24px 48px 0px rgba(0, 0, 0, 0.10);
$shadow-menu: 0px 10px 48px 0px rgba(0, 0, 0, 0.10);

//blur
$blur-8: 8;
$blur-16: 16;

//border-radius
$border-radius-4: 4;
$border-radius-5: 5;
$border-radius-6: 6;
$border-radius-8: 8;
$border-radius-10: 10;
$border-radius-12: 12;
$border-radius-16: 16;
$border-radius-20: 20;
$border-radius-24: 24;
$border-radius-28: 28;
$border-radius-32: 32;
$border-radius-40: 40;

//z-index
$z-index-100: 100;
$z-index-200: 200;
$z-index-300: 300;
$z-index-400: 400;
$z-index-500: 500;
$z-index-600: 600;
$z-index-700: 700;
$z-index-800: 800;
$z-index-900: 900;
$z-index-1000: 1000;

//gap 
$gap-8: 8;

// CSS
:root {
  --color-pink: #{$color-pink};
  --color-violet: #{$color-violet};
  --color-green: #{$color-green};
  --color-blue: #{$color-blue};
  --color-red: #{$color-red};
  --color-yellow: #{$color-yellow};

  --color-rose: #{$color-rose};
  --color-purple: #{$color-purple};
  --color-mint: #{$color-mint};
  --color-sky: #{$color-sky};
  --color-peach: #{$color-peach};
  --color-dust: #{$color-dust};

  --color-highlight-yellow: #{$color-highlight-yellow};
  --color-highlight-gold: #{$color-highlight-gold};

  // theme light
  --primary-color-light: #{$primary-color-light};
  --primary-color-hover-light: #{$primary-color-hover-light};
  --color-content-primary-light: #{$color-content-primary-light};
  --color-content-secondary-light: #{$color-content-secondary-light};
  --color-content-tertiary-light: #{$color-content-tertiary-light};
  --color-content-paragraph-primary-light: #{$color-content-paragraph-primary-light};
  --color-content-paragraph-secondary-light: #{$color-content-paragraph-secondary-light};
  --color-content-inverted-primary-light: #{$color-content-inverted-primary-light};
  --color-content-inverted-secondary-light: #{$color-content-inverted-secondary-light};
  --color-content-background-primary-light: #{$color-content-background-primary-light};
  --color-content-background-secondary-light: #{$color-content-background-secondary-light};
  --color-content-background-tertiary-light: #{$color-content-background-tertiary-light};
  --color-background-alternate-light: #{$color-background-alternate-light};
  --color-background-default-light: #{$color-background-default-light};
  --color-background-glassy-a-light: #{$color-background-glassy-a-light};
  --color-background-glassy-b-light: #{$color-background-glassy-b-light};
  --color-background-glassy-c-light: #{$color-background-glassy-c-light};
  --color-background-glassy-d-light: #{$color-background-glassy-d-light};
  --color-background-glassy-inverted-a-light: #{$color-background-glassy-inverted-a-light};
  --color-background-glassy-inverted-b-light: #{$color-background-glassy-inverted-b-light};
  --color-background-glassy-inverted-c-light: #{$color-background-glassy-inverted-c-light};
  --color-background-glassy-inverted-d-light: #{$color-background-glassy-inverted-d-light};
  --color-background-overlay-light: #{$color-background-overlay-light};
  --color-background-btn-light: #{$color-background-btn-light};
  --color-background-btn-hover-light: #{$color-background-btn-hover-light};
  --color-background-violet-light: #{$color-background-violet-light};
  --color-background-pink-light: #{$color-background-pink-light};
  --color-accent-alert-light: #{$color-accent-alert-light};
  --color-accent-info-light: #{$color-accent-info-light};
  --color-accent-success-light: #{$color-accent-success-light};
  --color-accent-warning-light: #{$color-accent-warning-light};
  --color-accent-promo-light: #{$color-accent-promo-light};
  --color-accent-promo-background-light: #{$color-accent-promo-background-light};

  --color-card-border-light: #{$color-card-border-light};
  --color-card-light: #{$color-card-light};
  --color-card-glassy-light: #{$color-card-glassy-light};
  --border-light: #{$border-light};

  // theme dark
  --primary-color-dark: #{$primary-color-dark};
  --primary-color-hover-dark: #{$primary-color-hover-dark};
  --color-content-primary-dark: #{$color-content-primary-dark};
  --color-content-secondary-dark: #{$color-content-secondary-dark};
  --color-content-tertiary-dark: #{$color-content-tertiary-dark};
  --color-content-paragraph-primary-dark: #{$color-content-paragraph-primary-dark};
  --color-content-paragraph-secondary-dark: #{$color-content-paragraph-secondary-dark};
  --color-content-inverted-primary-dark: #{$color-content-inverted-primary-dark};
  --color-content-inverted-secondary-dark: #{$color-content-inverted-secondary-dark};
  --color-content-background-primary-dark: #{$color-content-background-primary-dark};
  --color-content-background-secondary-dark: #{$color-content-background-secondary-dark};
  --color-content-background-tertiary-dark: #{$color-content-background-tertiary-dark};
  --color-background-default-dark: #{$color-background-default-dark};
  --color-background-alternate-dark: #{$color-background-alternate-dark};
  --color-background-glassy-a-dark: #{$color-background-glassy-a-dark};
  --color-background-glassy-b-dark: #{$color-background-glassy-b-dark};
  --color-background-glassy-c-dark: #{$color-background-glassy-c-dark};
  --color-background-glassy-d-dark: #{$color-background-glassy-d-dark};
  --color-background-glassy-inverted-a-dark: #{$color-background-glassy-inverted-a-dark};
  --color-background-glassy-inverted-b-dark: #{$color-background-glassy-inverted-b-dark};
  --color-background-glassy-inverted-c-dark: #{$color-background-glassy-inverted-c-dark};
  --color-background-glassy-inverted-d-dark: #{$color-background-glassy-inverted-d-dark};
  --color-background-overlay-dark: #{$color-background-overlay-dark};
  --color-background-btn-dark: #{$color-background-btn-dark};
  --color-background-btn-hover-dark: #{$color-background-btn-hover-dark};
  --color-background-violet-dark: #{$color-background-violet-dark};
  --color-background-pink-dark: #{$color-background-pink-dark};
  --color-accent-alert-dark: #{$color-accent-alert-dark};
  --color-accent-info-dark: #{$color-accent-info-dark};
  --color-accent-success-dark: #{$color-accent-success-dark};
  --color-accent-warning-dark: #{$color-accent-warning-dark};
  --color-accent-promo-dark: #{$color-accent-promo-dark};
  --color-accent-promo-background-dark: #{$color-accent-promo-background-dark};

  --color-card-border-dark: #{$color-card-border-dark};
  --color-card-dark: #{$color-card-dark};
  --color-card-glassy-dark: #{$color-card-glassy-dark};
  --border-dark: #{$border-dark};
}
