@import 'styles/index';

.wrapper {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  margin: set-calc(40) 0 set-calc(20);
 
  @include set-media(md) {
    margin: set-calc(30) 0 set-calc(16);
  }
}

.button {
  @include btn;
  @include button-56;
  @include button-special;

  @include set-media(md) {
    width: 100%;
  }
}