@import 'styles/index';

.mob {
  display: flex;
  flex-direction: column;

  padding: 0;
  border-radius: 0;

  width: 100%;
}

.close {
  display: none;

  @include set-media(md) {
    display: flex;
    @include icon-size(24);

    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;

    height: set-calc(64);
    width: set-calc(64);

    padding: set-calc(20) set-calc(20);
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  gap: set-calc(4);
  flex-grow: 1;
  flex-shrink: 0;

  width: 100%;
  height: max-content;
  padding: set-calc(0) set-calc(16) set-calc(16);
}

.header {
  display: flex;
  min-height: set-calc(64);
  justify-content: space-between;
  align-items: center;

  flex: 1 0 0;

  @include font-28-header;
  font-family: $font-family-medium;
}

.userWrapper {
  position: relative;

  display: flex;
  gap: set-calc(10);

  padding: set-calc(16);
  border-radius: set-calc($border-radius-16);
  background: var(--color-background-glassy-a);

  width: 100%;
  user-select: none;

  color: var(--color-content-paragraph-secondary);
  @include font-14-ui;
}

.user {
  display: flex;
  gap: set-calc(10);

  &__icon {
    @include icon-size(48);
    color: var(--color-content-inverted-primary);
  }

  &__content {
    display: flex;
    padding-top: set-calc(8);
    flex-direction: column;
    align-items: flex-start;
    gap: set-calc(6);
    flex: 1 0 0;

    &__name {
      @include font-16-ui;
      font-family: $font-family-medium;
    }

    &__email {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;

      align-self: stretch;

      @include font-14-ui;
      color: var(--color-content-tertiary);
    }
  }
}

.itemsGroup {
  display: flex;
  flex-direction: column;
  gap: set-calc(4);

  padding: set-calc(12) 0;

  border-bottom: 1px solid var(--border);
}

.item {
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: set-calc(48);
  padding: set-calc(10) set-calc(12);
  user-select: none;

  &__content {
    display: flex;
    align-items: center;

    gap: set-calc(10);
    flex: 1 0 0;

    &__icon {
      @include icon-size(20);
    }

    &__text {
      @include font-14-ui;
    }
  }
  
  .switch {
    @include switch-label-24-on;
  
    &_off {
      .icon {
        @include switch-off;
    
        .switchPin {
          transform: translate(set-calc(-20), 0);
        }
      }
    }
  }
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: set-calc(6);
  
  padding: set-calc(16) 0;

  &__btn {
    @include btn;
    @include button-56;
    @include button-primary;
    width: 100%;

    @include set-typography(18, 24, 500);
    touch-action: manipulation;
  }
}