@import 'styles/index';

.__ {
  position: relative;

  display: block;
  @include is-beige;
  padding: set-calc(32);
  background-color: $color-palette-black-700;
  border-radius: set-calc($border-radius-20);

  @include set-media(md) {
    padding: set-calc(32) set-calc(8);
  }
}


.title {
  color: var(--color-background-glassy-inverted-d);
  @include font-18-paragraph;
  margin-bottom: set-calc(18);

  @include set-media(md) {
    margin-bottom: set-calc(8);
    padding-left: set-calc(8);
  }
}

.list {
  display: flex;
  box-sizing: content-box;
  width: 100%;
  @include is-grid-3;
  grid-gap: set-calc(8);
}

.item {
  grid-column: span 1;
  display: flex;
  width: 100%;
  overflow: hidden;
  border-radius: set-calc($border-radius-8);
  background-color: $color-palette-black-50;
  text-align: center;
}

.item img {
  width: 100%;
  height: 100%;
}