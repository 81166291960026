@import 'styles/index';

.__ {
  box-sizing: border-box;
  max-width: set-calc(700);
  width: set-calc(-44, true, -44);
  padding-top: set-calc(40);
  padding-bottom: set-calc(120);

  @include set-media(md) {
    padding-top: set-calc(40);
    padding-bottom: 0;
  }
}

.buttons {
  display: flex;
  align-items: flex-start;

  @include set-media(md) {
    width: 100%;
  }
}

.nextLesson {
  display: flex;

  @include set-media(md) {
    width: 100%;
  }
}

.button {
  @include btn;
  @include button-56;
  @include button-black;

  @include set-media(md) {
    width: 100%;
  }
}