@import 'styles/index';

.__ {
  position: relative;

  margin: set-calc(12) 0 set-calc(20);
  margin-left: set-calc(48);
  
  color: var(--color-content-paragraph-primary);
  @include  font-20-article;
  font-family: $font-family-article;

  @include set-media(md) {
    margin: set-calc(16) 0 set-calc(8);
    margin-left: set-calc(36);
  }
}

.item {
  position: relative;

  margin-bottom: set-calc(8);

  span,
  p {
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
  }

  @include set-media(md) {
    margin-bottom: set-calc(8);
  }
}

.__ .item::before {
  position: absolute;
  top: set-calc(-2);
  left: set-calc(-30);
  width: set-calc(30);
  font-size: set-calc(24);
  content: "•";

  @include set-media(md) {
    top: set-calc(-1);
    left: set-calc(-24);
    width: set-calc(24);
    font-size: set-calc(20);
  }
}