@import 'styles/index';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  width: 100%;
  height: set-calc(697);

  padding: set-calc(40);
  border-radius: set-calc(40);
  background: $color-purple;
  background: url("../../../../../public/png/Home/community_cover_desk.png");
  background-size: contain;
  background-position: center;

  @include set-media(md) {
    background: transparent;
    height: unset;

    padding: 0;
    border-radius: 0;
  }
}

.header {
  @include header;
  color: $color-content-inverted-primary-light;

  border-radius: set-calc(40);
  background: rgba(255, 255, 255, 0.15);
  padding: set-calc(32);
  backdrop-filter: blur(set-calc($blur-16));

  margin-bottom: 0;

  flex: 1 1;

  @include set-media(md) {
    color: var(--color-content-primary);
  }
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  gap: set-calc(40);

  .chat {
    width: set-calc(450);
    height: auto;

    img {
      width: 100%;
      height: auto;
    }
  }

  @include set-media(md) {

    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    gap: 0;

    .chat {
      border-radius: set-calc(32);
      background-color: var(--color-background-violet);

      width: 100%;
      height: set-calc(465);
    }
  }
}