@import 'styles/index';

.wrapper {
  display: flex;
  flex-direction: column;
}

.label {
  @include input-label;
}

.caption {
  @include input-caption;

  // &_disabled {
  //   color: var(--color-content-tertiary);
  // }
}

.empty {
  @include input-empty;
}

.error {
  @include input-alert;
}

.valid {
  @include input-normal;
}

// .placeholder {
//   font-size: 16px;
//   position: absolute;
//   top: 16px;
//   left: 18px;
//   color: var(--text-secondary-color);
//   transition: transform .2s ease-in-out;
//   pointer-events: none;
//   overflow: hidden;
//   white-space: nowrap;
//   text-overflow: ellipsis;
//   max-width: calc(100% - 36px);
// }

// input:focus ~ .placeholder {
//   transform: translate(-14%, -44%) scale(.7);
// }
