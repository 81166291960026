@use "sass:math";
@use "sass:list";
@use "sass:map";
@use "sass:meta";
@use "sass:string";

//general
@function px-to-rem($list) {
  $rem-list: ();

  @each $value in $list {
    $rem-value: math.div($value, $rem-base) * 1rem;
    $rem-value-rounded: math.div(math.round($rem-value * 1000), 1000);
    $rem-list: append($rem-list, $rem-value-rounded);
  }

  @return #{$rem-list};
}

@mixin set-typography(
  $font-size,
  $line-height: $font-size,
  $text-transform: inherit
) {
  font-size: calc(var(--scale)*$font-size);
  line-height: calc(var(--scale)*$line-height);
  text-transform: $text-transform;
}

@mixin set-media($type) {
  @media (max-width: map-get($breakpoints, $type)) {
    @content;
  }
}

@mixin set-media-min($type) {
  @media (min-width: map-get($breakpoints, $type)) {
    @content;
  }
}

@mixin add-scale {
  :root {
     --scale: 100vw/1728;
  }
}

@mixin add-scale-md {
  :root {
     --scale: 100vw/414;
  }
}

@include add-scale;

@include set-media(md) {
  @include add-scale-md;
}

//layout
@mixin is-beige {
  position: relative;
  background-color: transparent;
  color: var(--color-content-primary);

  left: set-calc(-$p-page-desk-l);
  width: set-calc(2 * $p-page-desk-l, true, 100%);
  padding: set-calc(0) set-calc(24);

  @include set-media(md) {
    display: block;

    left: set-calc(-$p-page-mob-s);
    width: set-calc(2 * $p-page-mob-s, true, 100%);
    padding: 0 set-calc($p-page-mob-s);
  }
}

@mixin is-grid-2 {
  display: grid;
  grid-gap: set-calc($gap-8);
  grid-template-columns: repeat(2,minmax(0,1fr));

  @include set-media(md) {
    display: flex;
    flex-direction: column;
    grid-gap: set-calc($gap-8);
  }
}

@mixin is-grid-3 {
  
  display: grid;
  grid-gap: set-calc($gap-8);
  grid-template-columns: repeat(3,minmax(0,1fr));

  @include set-media(md) {
    display: flex;
    flex-direction: column;
    grid-gap: set-calc($gap-8);
  }
}

@mixin is-grid-4 {
  display: grid;
  grid-gap: set-calc($gap-8);
  grid-template-columns: repeat(4,minmax(0,1fr));

  @include set-media(md) {
    display: flex;
    flex-direction: column;
    grid-gap: set-calc($gap-8);
  }
}

@mixin is-grid-12 {
  display: grid;
  grid-gap: set-calc(4);
  grid-template-columns: repeat(12,minmax(0,1fr));

  @include set-media(md) {
    display: flex;
    flex-direction: column;
    grid-gap: set-calc(4);
  }
}

//borders
@mixin rounded($height) {
  border-radius: calc(set-calc($height) / 2)
}

//links
@mixin inline-link {
  transition: color $d-hover ease-out 0s;
  -o-transition: color $d-hover ease-out 0s;
  -moz-transition: color $d-hover ease-out 0s;
  -ms-transition: color $d-hover ease-out 0s;
  -webkit-transition: color $d-hover ease-out 0s;

  cursor: pointer;

  &:hover {
    color: var(--primary-color);
  }

  &:active {
    color: var(--primary-color);
  }

  &-text {
    display: inline;
    vertical-align: top;
    color: inherit;
  }
  
  &-arrow {
    display: inline-block;
    vertical-align: top;
    line-height: set-calc(28);
    margin-left: set-calc(6);

    @include set-media(md) {
      line-height: set-calc(22);
    }
  }

  &-arrow-left {
    display: inline-block;
    vertical-align: top;
    line-height: set-calc(28);
    margin-right: set-calc(6);

    @include set-media(md) {
      line-height: set-calc(22);
    }
  }
}

@mixin s-hoverable {
  cursor: pointer;
  transition: color $d-hover ease-out 0.1s;
  -o-transition: color $d-hover ease-out 0.1s;
  -moz-transition: color $d-hover ease-out 0.1s;
  -ms-transition: color $d-hover ease-out 0.1s;
  -webkit-transition: color $d-hover ease-out 0.1s;

  &:hover {
    color: var(--primary-color);
  }
}

@mixin key-link(
  $color: var(--color-accent-promo-background)
  ) {
  position: relative;
  display: inline-block;
  color: inherit;
  z-index: 1;

  transition: all $d-hover ease-out 0s;
  -o-transition: all $d-hover ease-out 0s;
  -moz-transition: all $d-hover ease-out 0s;
  -ms-transition: all $d-hover ease-out 0s;
  -webkit-transition: all $d-hover ease-out 0s;
  cursor: pointer;

  width: fit-content;
  -webkit-tap-highlight-color: transparent;

  &:hover::before {
    height: 100%;
  }

  @include set-media(md) {
    &:hover::before {
      height: 2px;
    }
  }

  &::before {
    position: absolute;
    content: "";

    bottom: 0;
    left: -2px;
    z-index: -1;

    width: calc(100% + 4px);
    height: 2px;

    border-radius: set-calc(4);
    background-color: $color;
    transition: height .2s cubic-bezier(.25,.01,.25,1) 0s;
  }
}

//header
@mixin header {
  position: relative;

  display: flex;
  flex-direction: column;

  gap: set-calc(24);
  padding: 0 set-calc(16);
  margin-bottom: set-calc(40);

  &__title {
    @include font-56-header;
    font-family: $font-family-medium;
  }

  &__description {
    @include font-18-paragraph;

    max-width: set-calc(600);
  }

  @include set-media(md) {
    width: 100%;

    gap: set-calc(16);
    padding: 0 set-calc(8);
    margin-bottom: set-calc(24);
  }
}

//section
@mixin section-title {
  display: flex;
  padding: 0 set-calc(8) set-calc(16);

  color: var(--color-content-paragraph-primary);
  @include font-18-ui;
  text-transform: uppercase;
  font-family: $font-family-normal;

  @include set-media(md) {
    @include font-14-ui;
    color: var(--color-content-paragraph-secondary);
  }
}

//button
@mixin btn {
  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  flex-shrink: 0;

  text-align: center;
  
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  transition-property: all;
  transition-duration: $d-hover;
  transition-timing-function: ease-out;

  box-shadow: none;

  &_loading {
    opacity: 0.8;
    cursor: default;
    pointer-events: none;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    gap: set-calc(2);

    font-size: inherit;
    line-height: inherit;

    .loader {
      color: inherit;
    }
  }

  &:hover {
    opacity: 1;
    cursor: pointer;
  }

  &:disabled {
    cursor: default;
    pointer-events: none;

    color: var(--color-background-glassy-d);
    background: var(--color-background-glassy-c);
  }

  &:active {
    transform: scale(0.96);
    box-shadow: none;
  }

  @include set-media(md) {
    transition-property: none;
    
    &:active {
      transform: none;
    }
  }
}

@mixin button-primary {
  color: $color-content-inverted-primary-light;
  background-color: var(--primary-color);

  &:hover {
    background-color: var(--primary-color-hover);
  }

  @include set-media(md) {
    &:hover {
      background-color: var(--primary-color);
    }
  }
}

@mixin button-black {
  color: var(--color-content-inverted-primary);
  background-color: var(--color-background-btn);

  &:hover {
    background-color: var(--color-background-btn-hover);
  }

  @include set-media(md) {
    &:hover {
      background-color: var(--color-background-btn);
    }
  }
}

@mixin button-invisible {
  color: var(--color-content-primary);
  background-color: transparent;

  &:hover {
    background-color: var(--color-background-glassy-b);
  }

  @include set-media(md) {
    &:hover {
      background-color: transparent;
      color: var(--color-content-primary);
    }
  }
}

@mixin button-secondary {
  color: var(--color-content-primary);
  background-color: var(--color-background-glassy-b);

  &:hover {
    background-color: var(--color-background-glassy-c);
  }

  @include set-media(md) {
    &:hover {
      background-color: var(--color-background-glassy-b);
    }
  }
}

@mixin button-special( 
  $color: var(--color-content-inverted-primary),
  $bc: var(--color-accent-promo),
  ) {
  background-color: $bc;
  color: $color;

  &:hover {
    opacity: 0.9;
  }

  @include set-media(md) {
    &:hover {
      opacity: 1;
    }
  }
}

@mixin button-outline(
  $color: var(--color-content-primary),
  $bc-color: var(--color-content-inverted-primary)
) {
  border: 1px solid $color;
  color: $color;
  background: transparent;

  &:hover {
    background: $color;
    color: $bc-color;
  }

  &:disabled {
    color:  var(--color-background-glassy-d);
    background:  var(--color-background-glassy-a);
    border-color:  var(--color-background-glassy-d);
  }

  @include set-media(md) {

    &:hover {
      background: transparent;
      color: $color;
    }
  }
}

@mixin button-3d(
  $bc: transparent,
  $colorHover: var(--color-background-glassy-a),
  $color: $color-palette-black-200,
  $colorActive: $color-palette-black-300,
  $opacity: 1,
  $width: 2px,
  ) {

  box-shadow: 0 0 0 calc($width / 2) $color, 0 $width 0 $width $color;
  transition-property: all;
  transition-duration: $d-hover;
  transition-timing-function: ease-out;

  background-color: $bc;

  &:hover {
    background-color: $colorHover;
    opacity: $opacity;
  }

  &:active {
    box-shadow: 0 0 0 calc($width / 2) $colorActive;
    transform: translate3d(0, $width, 0);
  }

  @include set-media(md) {
    transition-property: all;
    transition-duration: $d-hover;
    transition-timing-function: ease-out;
    
    background-color: $bc;
  }
}

@mixin button-40 {
  height: set-calc(40);

  padding: 0 set-calc(12);
  border-radius: set-calc($border-radius-8);


  @include font-14-ui;

  .content {
    svg {
      width: set-calc(24);
      height: set-calc(24);
    }

    .loader {
      width: set-calc(12);
      height: set-calc(12);
    }
  }
}

@mixin button-48 {
  height: set-calc(48);
  
  padding: 0 set-calc(16);
  border-radius: set-calc($border-radius-10);

  @include font-16-ui;

  .content {
    svg {
      width: set-calc(24);
      height: set-calc(24);
    }

    .loader svg {
      width: set-calc(12);
      height: set-calc(12);
    }
  }
}

@mixin button-56 {
  height: set-calc(56);
  
  padding: 0 set-calc(16);
  border-radius: set-calc($border-radius-12);

  @include font-18-ui;

  .content {
    gap: set-calc(4);

    svg {
      width: set-calc(32);
      height: set-calc(32);
    }

    .loader svg {
      width: set-calc(16);
      height: set-calc(16);
    }
  }
}

//icon
@mixin icon-size (
  $size
) {
  width: set-calc($size);
  height: set-calc($size);

  svg {
    display: block;
    overflow: hidden;
    flex-shrink: 0;

    width: set-calc($size);
    height: set-calc($size);
    fill: currentColor;
  }
}

@mixin icon-button-64 {
  @include icon-size(32);

  display: flex;
  justify-content: center;
  align-items: center;

  height: set-calc(64);
  padding: 0 set-calc(16);
  border-radius: set-calc($border-radius-12);

  svg {
    transition-property: all;
    transition-duration: $d-hover;
    transition-timing-function: ease-out;

    path {
      transition-property: all;
      transition-duration: $d-hover;
      transition-timing-function: ease-out;
    }
  }
}

@mixin icon-button-40 {
  @include icon-size(24);

  display: flex;
  justify-content: center;
  align-items: center;

  height: set-calc(40);
  padding: 0 set-calc(8);
  border-radius: set-calc($border-radius-8);

  svg {
    transition-property: all;
    transition-duration: $d-hover;
    transition-timing-function: ease-out;

    path {
      transition-property: all;
      transition-duration: $d-hover;
      transition-timing-function: ease-out;
    }
  }
}

@mixin icon-button-32 {
  @include icon-size(20);

  display: flex;
  justify-content: center;
  align-items: center;

  height: set-calc(32);
  padding: 0 set-calc(6);
  border-radius: set-calc($border-radius-6);
  
  svg {
    transition-property: all;
    transition-duration: $d-hover;
    transition-timing-function: ease-out;

    path {
      transition-property: all;
      transition-duration: $d-hover;
      transition-timing-function: ease-out;
    }
  }
}

@mixin icon-button-invisible {
  background: transparent;
  color: var(--color-content-primary);

  &:hover {
    background: var(--color-background-glassy-b);
  }

  &:disabled {
    background: var(--color-background-glassy-c);
    color: var(--color-background-glassy-d);
  }

  @include set-media(md) {
    &:hover {
      background: transparent;
    }
  }
}

@mixin icon-button-invisible-secondary {
  background: transparent;
  color: var(--color-content-tertiary);

  &:hover {
    background: var(--color-background-glassy-b);
  }

  &:disabled {
    background: var(--color-background-glassy-c);
    color: var(--color-background-glassy-d);
  }

  @include set-media(md) {
    &:hover {
      background: transparent;
    }
  }
}

@mixin icon-button-secondary {
  background: var(--color-background-glassy-b);
  color: var(--color-content-primary);

  &:hover {
    background: var(--color-background-glassy-c);
  }

  &:disabled {
    background: var(--color-background-glassy-c);
    color: var(--color-background-glassy-d);
  }

  @include set-media(md) {
    &:hover {
      background: transparent;
    }
  }
}

@mixin icon-button-primary {
  background: var(--color-content-primary);
  color: var(--color-content-inverted-primary);

  &:hover {
    opacity: 0.9;
  }

  &:disabled {
    background: var(--color-background-glassy-c);
    color: var(--color-background-glassy-d);
  }

  @include set-media(md) {
    &:hover {
      background: var(--color-content-primary);
      opacity: 1;
    }
  }
}

//dropdown
@mixin dropdown {
  position: relative;

  display: inline-flex;
  align-items: center;
  width: max-content;

  color: inherit;

  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;

  svg {
    color: inherit;

    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &_isOpened {
    svg {
      transform: rotate(180deg);
    }
  }

  &:disabled {
    cursor: default;
    pointer-events: none;
  }
}

@mixin dropdown-primary {
  &:hover {
    color: var(--primary-color);
  }

  &:disabled {
    color: var(--color-background-glassy-d);
  }
}

@mixin dropdown-secondary {
  border: 1px solid var(--border);
  background: var(--color-background-glassy-a);

  &:hover {
    background: var(--color-background-glassy-b);
    color: var(--color-content-primary);
  }

  &:disabled {
    color: var(--color-background-glassy-d);
    background: var(--color-background-glassy-c);
  }
}

@mixin dropdown-48 {
  height: set-calc(48);
  border-radius: set-calc($border-radius-10);

  padding: 0 set-calc(8) 0 set-calc(12);

  &__content {
    @include font-16-ui;
  }

  svg {
    display: block;

    width: set-calc(24);
    height: set-calc(24);
  }
}

@mixin dropdown-40 {
  height: set-calc(40);
  border-radius: set-calc($border-radius-8);

  padding: 0 set-calc(8) 0 set-calc(12);

  &__content {
    @include font-14-ui;
  }

  svg {
    display: block;
    
    width: set-calc(24);
    height: set-calc(24);
  }
}

@mixin dropdown-32 {
  height: set-calc(32);
  border-radius: set-calc($border-radius-8);

  padding: 0 set-calc(4) 0 set-calc(8);

  &__content {
    @include font-14-ui;
  }

  svg {
    display: block;
    
    width: set-calc(24);
    height: set-calc(24);
  }
}

//bage
@mixin bage {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    font-size: inherit;
    line-height: inherit;
  }
}

@mixin bage-primary {    
  background: var(--color-accent-promo-background, #5627FF);
  color: var(--color-content-primary);
}

@mixin bage-secondary {
  background: var(--color-background-glassy-a, rgba(255, 255, 255, 0.05));
  color: var(--color-content-primary);
}

@mixin bage-outline {
  border: 1px solid var(--color-background-glassy-d, rgba(0, 0, 0, 0.20));
  color: var(--color-content-primary);
}

@mixin bage-blur {
  background: var(--color-background-glassy-inverted-d, rgba(255, 255, 255, 0.30));
  backdrop-filter: blur(calc($blur-8));
  color: var(--color-content-primary);
}

@mixin bage-64 {
  height: set-calc(64);
  @include rounded(64);

  padding: 0 set-calc(16);
  @include font-20-ui;

  .content {
    @include icon-size(28);
    gap: set-calc(8);
  }
}

@mixin bage-56 {
  height: set-calc(56);
  @include rounded(56);

  padding: 0 set-calc(16);
  @include font-18-ui;

  .content {
    @include icon-size(24);
    gap: set-calc(8);
  }
}

@mixin bage-48 {
  height: set-calc(48);
  @include rounded(48);

  padding: 0 set-calc(14);
  @include font-18-ui;

  .content {
    @include icon-size(24);
    gap: set-calc(4);
  }
}

@mixin bage-40 {
  height: set-calc(40);
  @include rounded(40);

  padding: 0 set-calc(12);
  @include font-16-ui;

  .content {
    @include icon-size(20);
    gap: set-calc(4);
  }
}

@mixin bage-32 {
  height: set-calc(32);
  @include rounded(32);

  padding: 0 set-calc(10);
  @include font-12-ui;

  .content {
    @include icon-size(12);
    gap: set-calc(2);
  }
}

@mixin bage-24 {
  height: set-calc(24);
  @include rounded(24);

  padding: 0 set-calc(6);
  @include font-10-ui;

  .content {
    @include icon-size(12);
    gap: set-calc(2);
  }
}

//switch
@mixin switch {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: transparent;

  transition-property: all;
  transition-duration: $d-hover;
  transition-timing-function: ease-out;

  .switchPin {
    position: absolute;

    transition-property: all;
    transition-duration: $d-hover;
    transition-timing-function: ease-out;
  }
}

@mixin switch-16-on {
  width: set-calc(34);
  height: set-calc(22);
  @include rounded(22);

  padding: set-calc(3);

  .fill {
    width: set-calc(28);
    height: set-calc(16);
    @include rounded(16);

    background-color: var(--primary-color);
  }

  .switchPin {
    right: set-calc(5);
    top: set-calc(5);

    width: set-calc(12);
    height: set-calc(12);
    @include rounded(12);

    background: var(--color-content-inverted-primary);
  }
}

@mixin switch-16-off {
  width: set-calc(34);
  height: set-calc(22);
  @include rounded(22);

  padding: set-calc(3);

  .fill {
    width: set-calc(28);
    height: set-calc(16);
    @include rounded(16);

    background-color: var(--color-background-glassy-d);
  }

  .switchPin {
    left: set-calc(5);
    top: set-calc(5);

    width: set-calc(12);
    height: set-calc(12);
    @include rounded(12);

    background: var(--color-content-inverted-primary);
  }
}

@mixin switch-20-on {
  width: set-calc(42);
  height: set-calc(26);
  @include rounded(26);

  padding: set-calc(3);

  .fill {
    width: set-calc(36);
    height: set-calc(20);
    @include rounded(20);

    background-color: var(--primary-color);
  }

  .switchPin {
    right: set-calc(5);
    top: set-calc(5);

    width: set-calc(16);
    height: set-calc(16);
    @include rounded(16);

    background: var(--color-content-inverted-primary);
  }
}

@mixin switch-20-off {
  width: set-calc(42);
  height: set-calc(26);
  @include rounded(26);

  padding: set-calc(3);

  .fill {
    width: set-calc(36);
    height: set-calc(20);
    @include rounded(20);

    background-color: var(--color-background-glassy-d);
  }

  .switchPin {
    left: set-calc(5);
    top: set-calc(5);

    width: set-calc(16);
    height: set-calc(16);
    @include rounded(16);

    background: var(--color-content-inverted-primary);
  }
}

@mixin switch-24-on {
  width: set-calc(52);
  height: set-calc(32);
  @include rounded(32);

  padding: set-calc(4);

  .fill {
    width: set-calc(44);
    height: set-calc(24);
    @include rounded(24);

    background-color: var(--primary-color);
  }

  .switchPin {
    right: set-calc(6);
    top: set-calc(6);

    width: set-calc(20);
    height: set-calc(20);
    @include rounded(20);

    background: var(--color-content-inverted-primary);
  }
}

@mixin switch-24-off {
  width: set-calc(52);
  height: set-calc(32);
  @include rounded(32);

  padding: set-calc(4);

  .fill {
    width: set-calc(44);
    height: set-calc(24);
    @include rounded(24);

    background-color: var(--color-background-glassy-d);
  }

  .switchPin {
    left: set-calc(6);
    top: set-calc(6);

    width: set-calc(20);
    height: set-calc(20);
    @include rounded(20);

    background: var(--color-content-inverted-primary);
  }
}

@mixin switch-hover {
  background: var(--color-background-glassy-b);
  cursor: pointer;

  @include set-media(md) {
    background: transparent;
  }
}

@mixin switch-off {
  .fill {
    background-color: var(--color-background-glassy-d);
  }

  .switchPin {
    background: var(--color-content-inverted-primary);
  }

  &:disabled {
    cursor: default;
    pointer-events: none;

    .fill {
      background: var(--color-background-glassy-c);
    }

    .switchPin {
      background: var(--color-background-glassy-d);
    }
  }
}

@mixin switch-on {
  .fill {
    background-color: var(--primary-color);
  }

  .switchPin {
    background: var(--color-content-inverted-primary);
  }

  &:disabled {
    cursor: default;
    pointer-events: none;

    .fill {
      background: var(--color-background-glassy-c);
    }
  
    .switchPin {
      background: var(--color-content-inverted-primary);
    }
  }
}

//switch-label
@mixin switch-label-16-on {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: set-calc(6);

  .label {
    @include font-12-ui;
    color: var(--color-content-primary);

    transition-property: all;
    transition-duration: $d-hover;
    transition-timing-function: ease-out;
  }

  .icon {
    @include switch;
    @include switch-16-on;
  }

  &:hover {
    cursor: pointer;

    .icon {
      @include switch-hover;
    }
  }

  &:disabled {
    cursor: default;
    pointer-events: none;

    .label {
      color: var(--color-background-glassy-d);
    }

    .icon {
      .fill {
        background: var(--color-background-glassy-c);
      }
    
      .switchPin {
        background: var(--color-content-inverted-primary);
      }
    }
  }
}

@mixin switch-label-16-off {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: set-calc(6);

  .label {
    @include font-12-ui;
    color: var(--color-content-tertiary);

    transition-property: all;
    transition-duration: $d-hover;
    transition-timing-function: ease-out;
  }

  .icon {
    @include switch;
    @include switch-16-off;
  }

  &:hover {
    cursor: pointer;

    .icon {
      @include switch-hover;
    }
  }

  &:disabled {
    cursor: default;
    pointer-events: none;

    .label {
      color: var(--color-background-glassy-d);
    }

    .icon {
      .fill {
        background: var(--color-background-glassy-c);
      }
  
      .switchPin {
        background: var(--color-background-glassy-d);
      }
    }
  }
}

@mixin switch-label-20-on {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: set-calc(8);

  .label {
    @include font-14-ui;
    color: var(--color-content-primary);

    transition-property: all;
    transition-duration: $d-hover;
    transition-timing-function: ease-out;
  }

  .icon {
    @include switch;
    @include switch-20-on;
  }

  &:hover {
    cursor: pointer;

    .icon {
      @include switch-hover;
    }
  }

  &:disabled {
    cursor: default;
    pointer-events: none;

    .label {
      color: var(--color-background-glassy-d);
    }


    .icon {
      .fill {
        background: var(--color-background-glassy-c);
      }
    
      .switchPin {
        background: var(--color-content-inverted-primary);
      }
    }
  }
}

@mixin switch-label-20-off {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: set-calc(8);

  .label {
    @include font-14-ui;
    color: var(--color-content-tertiary);

    transition-property: all;
    transition-duration: $d-hover;
    transition-timing-function: ease-out;
  }

  .icon {
    @include switch;
    @include switch-20-off;
  }

  &:hover {
    cursor: pointer;

    .icon {
      @include switch-hover;
    }
  }

  &:disabled {
    cursor: default;
    pointer-events: none;

    .label {
      color: var(--color-background-glassy-d);
    }

    .icon {
      .fill {
        background: var(--color-background-glassy-c);
      }
  
      .switchPin {
        background: var(--color-background-glassy-d);
      }
    }
  }
}

@mixin switch-label-24-on {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: set-calc(10);

  .label {
    @include font-16-ui;
    color: var(--color-content-primary);

    transition-property: all;
    transition-duration: $d-hover;
    transition-timing-function: ease-out;
  }

  .icon {
    @include switch;
    @include switch-24-on;
  }

  &:hover {
    cursor: pointer;

    .icon {
      @include switch-hover;
    }
  }

  &:disabled {
    cursor: default;
    pointer-events: none;

    .label {
      color: var(--color-background-glassy-d);
    }


    .icon {
      .fill {
        background: var(--color-background-glassy-c);
      }
    
      .switchPin {
        background: var(--color-content-inverted-primary);
      }
    }
  }
}

@mixin switch-label-24-off {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: set-calc(10);

  .label {
    @include font-16-ui;
    color: var(--color-content-tertiary);

    transition-property: all;
    transition-duration: $d-hover;
    transition-timing-function: ease-out;
  }

  .icon {
    @include switch;
    @include switch-24-off;
  }

  &:hover {
    cursor: pointer;

    .icon {
      @include switch-hover;
    }
  }

  &:disabled {
    cursor: default;
    pointer-events: none;

    .label {
      color: var(--color-background-glassy-d);
    }

    .icon {
      .fill {
        background: var(--color-background-glassy-c);
      }
  
      .switchPin {
        background: var(--color-background-glassy-d);
      }
    }
  }
}

//chips
@mixin chips {
  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  flex-shrink: 0;

  text-align: center;
  
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  transition-property: all;
  transition-duration: $d-hover;
  transition-timing-function: ease-out;

  box-shadow: none;

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    gap: set-calc(4);

    font-size: inherit;
    line-height: inherit;
  }

  &:hover {
    opacity: 1;
    cursor: pointer;
  }

  &:disabled {
    cursor: default;
    pointer-events: none;

    color: var(--color-background-glassy-d);
    background: var(--color-background-glassy-c);
  }

  @include set-media(md) {
    transition-property: none;
  }
}

@mixin chips-secondary {
  background: var(--color-background-glassy-a);
  color: var(--color-content-primary);

  &:hover {
    background: var(--color-background-glassy-b);
  }

  &:disabled {
    background: var(--color-background-glassy-c);
    color: var(--color-background-glassy-d);
  }

  &_selected {
    pointer-events: none;
    background: var(--color-content-primary);
    color: var(--color-content-inverted-primary);
  }

  @include set-media(md) {
    &:hover {
      background: var(--color-background-glassy-a);
    }
  }
}

@mixin chips-special (
  $color: var(--color-content-primary),
  $bc:  var(--color-accent-promo-background),
  ) {
    
  background: var(--color-background-glassy-b);
  color: $color;

  &:hover {
    background: var(--color-background-glassy-c);
  }

  &:disabled {
    background: var(--color-background-glassy-c);
    color: var(--color-background-glassy-d);
  }

  &_selected {
    pointer-events: none;
    background: $bc;
    color: var(--color-content-primary);
  }

  @include set-media(md) {
    &:hover {
      background: var(--color-background-glassy-b);
    }
  }
}

@mixin chips-outline {
  background: transparent;
  color: var(--color-content-paragraph-primary);
  border: 1px solid var(--color-background-glassy-d);

  &:hover {
    background: var(--color-background-glassy-a);
    border: 1px solid var(--color-background-glassy-d);
  }

  &:disabled {
    background: var(--color-background-glassy-c);
    color: var(--color-background-glassy-d);
  }

  &_selected {
    pointer-events: none;
    background: transparent;
    border: 2px solid var(--color-content-primary);
  }

  @include set-media(md) {
    &:hover {
      background: transparent;
    }
  }
}

@mixin chips-invisible {

  background: transparent;
  color: var(--color-content-primary);

  &:hover {
    background: var(--color-background-glassy-b);
  }

  &:disabled {
    background: var(--color-background-glassy-c);
    color: var(--color-background-glassy-d);
  }

  &_selected {
    pointer-events: none;
    background: var(--color-accent-promo-background);
    color: var(--color-content-primary);
  }

  @include set-media(md) {
    &:hover {
      background: transparent;
    }
  }
}

@mixin chips-24 {
  height: set-calc(24);
  @include rounded(24);

  padding: 0 set-calc(6);
  @include font-10-ui;

  .content {
    gap: set-calc(4);
    @include icon-size(12);
  }
}

@mixin chips-32 {
  height: set-calc(32);
  @include rounded(32);

  padding: 0 set-calc(10);
  @include font-12-ui;

  .content {
    gap: set-calc(4);
    @include icon-size(12);
  }
}

@mixin chips-40 {
  height: set-calc(40);
  @include rounded(40);

  padding: 0 set-calc(12);
  @include font-16-ui;

  .content {
    gap: set-calc(4);
    @include icon-size(20);
  }
}

@mixin chips-64 {
  height: set-calc(64);
  @include rounded(64);

  padding: 0 set-calc(16);
  @include font-20-ui;

  .content {
    gap: set-calc(8);
    @include icon-size(28);
  }
}

//input
@mixin input {
  position: relative;
  appearance: none;

  color: var(--color-background-glassy-d);

  background: transparent;

  border-color: var(--border);
  border-style: solid;
  
  box-shadow: none;
  outline: none;

  font-family: $font-family-normal!important;

  transition-property: all;
  transition-duration: $d-focus;
  transition-timing-function: ease-out;

  &::placeholder,
  &::-ms-input-placeholder,
  &::-webkit-input-placeholder {
    color: var(--color-background-glassy-d)!important;
    font-family: $font-family-normal!important;
    opacity: 1;
  }

  &:is(:-webkit-autofill, :autofill, :-webkit-autofill:focus, :-webkit-autofill:hover) {
    background-color: transparent;
    background: transparent;
    color: var(--color-content-primary);
  }

  &:focus {
    border-color: var(--color-accent-notice);
    background-color: transparent;
    color: var(--color-content-primary);
  }

  &:disabled {
    border-color: transparent;
    background-color: var(--color-background-glassy-a);
    color: var(--color-background-glassy-d);

    &+ .caption {
      color: var(--color-content-tertiary);
    }
  }
}

@mixin input-caption {
  @include font-10-ui;
  font-family: $font-family-normal;
  color: var(--color-content-tertiary);

  margin-top: set-calc(4);
  margin-left: set-calc(8);
}

@mixin input-label {
  @include font-12-ui;
  font-family: $font-family-medium;

  margin-left: set-calc(8);
  margin-bottom: set-calc(4);

  &_disabled {
    color: var(--color-content-tertiary);
  }
}

@mixin input-large {
  height: set-calc(64);
  padding: 0 set-calc(16);

  border-width: 1.5px;
  border-radius: set-calc($border-radius-12);

  @include font-16-ui;
}

@mixin input-medium {
  height: set-calc(44);
  padding: 0 set-calc(12);

  border-width: 1px;
  border-radius: set-calc($border-radius-8);

  @include font-14-normal-ui;
}

@mixin input-alert {
  border-color: var(--color-accent-alert);

  [data-input-state-error] & {
    border-color: var(--color-accent-alert);

    &:focus {
      border-color: var(--color-accent-alert);
    }
  }

  & + .caption {
    color: var(--color-accent-alert);
  }
}

@mixin input-normal {
  color: var(--color-content-primary);
  background-color: transparent;
}

@mixin input-empty {
  color: var(--color-background-glassy-d);
}

//tabs
@mixin tabsMenu {
  display: inline-flex;
  gap: set-calc(2);

  height: set-calc(56);
  @include rounded(56);

  width: max-content;

  padding: set-calc(8);
  background: var(--color-card);
}

//slideControl
@mixin slideControl {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  padding: set-calc(8) 0;
  gap: set-calc(10);

  .item {
    display: flex;
    flex: 1 1;

    height: set-calc(4);
    border-radius: set-calc(4);
    background-color: var(--color-background-glassy-c);
  }

  .item_active {
    background: linear-gradient(to right, var(--color-content-primary) 50%, var(--color-background-glassy-c) 50%);
    background-size: 200% 100%;
    animation: slideControl linear infinite;
    animation-duration: $d-slide;
  }
}

//animation
@mixin contentExpander {
  animation: theoryFragmentAppearance .3s;
  animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 0.1);
  animation-delay: .3s;
  animation-fill-mode: backwards;
}

//keyframes

@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
}

@mixin apply-to-all($mixin, $list) {
  @each $element in $list {
    @include meta.apply($mixin, $element);
  }
}

@keyframes itemPopover {
  0% {
    bottom: calc(100% - 6px);
  }

  25% {
      bottom: calc(100% - 8px);
  }

  50% {
      bottom: calc(100% - 14px);
  }

  75% {
      bottom: calc(100% - 8px);
  }

  100% {
      bottom: calc(100% - 6px);
  }
}

@keyframes fadeEffect {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes slideControl {
	0% {
		background-position: 100% 0;
	}
	100% {
		background-position: 0 0;
	}
}

@keyframes marqueeAnimation {
  0% {transform: translate(0)}
  10% {transform: translate(-5%)}
  100% {transform: translate(-50%)}
}

@keyframes theoryFragmentAppearance{
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: none;
    opacity: 1;
  }
}

@function set-calc(
  $value,
  $bollean: false,
  $value2: 0%
  ) {
    @if $bollean {
      @return calc($value2 + var(--scale) * $value);
    } @else { @return calc(var(--scale) * $value) }
}
