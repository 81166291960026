@import 'styles/index';

.profilePage {
  display: flex;
  position: relative;
  flex-direction: column;

  width: 100%;
  max-width: set-calc(1328);
  margin: set-calc(32) auto set-calc(40);

  @include set-media(md) {
    margin: set-calc(24) auto;
  }
}

.profilePage__content {
  display: flex;
  flex-direction: column;
  gap: set-calc(80);

  flex-grow: 1;
  max-width: 100%;

  @include set-media(md) {

    width: 100%;
    gap: set-calc(64);
  }
}

// .asideWrapper {
//   grid-column: span 4;

//   @include set-media(md) {
//     margin-left: 0;
//     width: 100%;
//   }
// }

.section {
  @include is-grid-12;
}
