@import 'styles/index';

.__ {
  z-index: 600;
  position: sticky;

  top: 0;
  left: 0;
  bottom: 0;

  height: 100vh;

  @include set-media(md) {
    display: none;
  }
}

.container {
  position: relative;

  display: flex;
  flex-direction: column;
  height: 100%;

  padding: set-calc(12) set-calc(8);
}

.header {
  @include btn;
  @include icon-size(32);

  width: set-calc(40);

  margin-bottom: set-calc(24);
}

.items {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: set-calc(16);
}

.items:nth-child(3) {
  margin-top: set-calc(16);
}

.items:last-child {
  margin-top: auto;
  justify-content: end;
}

.item {
  @include btn;
  @include icon-button-40;
  @include icon-button-invisible-secondary;

  width: set-calc(40);
}

.item[data-user-wrapper] {
  margin-top: set-calc(24);

  &:hover {
    background-color: transparent;
  }
}

.icon {
  svg[data-fill] {
    path {
      fill: var(--color-content-primary);
    }
  }

  svg[data-user] {
    width: set-calc(32);
    height: set-calc(32);

    path {
      fill: var(--color-background-glassy-d);
    }
  }

  @include set-media(md) {
    display: none;
  }
}

.open {
  z-index: 10000;
}