
body.light {
  --primary-color: var(--primary-color-light);
  --primary-color-hover: var(--primary-color-hover-light);

  --color-content-primary: var(--color-content-primary-light);
  --color-content-secondary: var(--color-content-secondary-light);
  --color-content-tertiary: var(--color-content-tertiary-light);
  --color-content-paragraph-primary: var(--color-content-paragraph-primary-light);
  --color-content-paragraph-secondary: var(--color-content-paragraph-secondary-light);
  --color-content-inverted-primary: var(--color-content-inverted-primary-light);
  --color-content-inverted-secondary: var(--color-content-inverted-secondary-light);
  --color-content-background-primary: var(--color-content-background-primary-light);
  --color-content-background-secondary: var(--color-content-background-secondary-light);
  --color-content-background-tertiary: var(--color-content-background-tertiary-light);

  --color-background-alternate: var(--color-background-alternate-light);
  --color-background-default: var(--color-background-default-light);
  --color-background-glassy-a: var(--color-background-glassy-a-light);
  --color-background-glassy-b: var(--color-background-glassy-b-light);
  --color-background-glassy-c: var(--color-background-glassy-c-light);
  --color-background-glassy-d: var(--color-background-glassy-d-light);
  --color-background-glassy-inverted-a: var(--color-background-glassy-inverted-a-light);
  --color-background-glassy-inverted-b: var(--color-background-glassy-inverted-b-light);
  --color-background-glassy-inverted-c: var(--color-background-glassy-inverted-c-light);
  --color-background-glassy-inverted-d: var(--color-background-glassy-inverted-d-light);
  --color-background-overlay: var(--color-background-overlay-light);
  --color-background-btn: var(--color-background-btn-light);
  --color-background-btn-hover: var(--color-background-btn-hover-light);
  --color-background-violet: var(--color-background-violet-light);
  --color-background-pink: var(--color-background-pink-light);

  --color-accent-alert: var(--color-accent-alert-light);
  --color-accent-info: var(--color-accent-info-light);
  --color-accent-success: var(--color-accent-success-light);
  --color-accent-warning: var(--color-accent-warning-light);
  --color-accent-promo: var(--color-accent-promo-light);
  --color-accent-promo-background: var(--color-accent-promo-background-light);

  --color-card-border: var(--color-card-border-light);
  --color-card: var(--color-card-light);
  --color-card-glassy: var(--color-card-glassy-light);
  --border: var(--border-light);

  --color-quiz: var(--color-quiz-light);
  --shadow-solid-small: var(--shadow-solid-small-light);
}

body.dark {
  --primary-color: var(--primary-color-dark);
  --primary-color-hover: var(--primary-color-hover-dark);

  --color-content-primary: var(--color-content-primary-dark);
  --color-content-secondary: var(--color-content-secondary-dark);
  --color-content-tertiary: var(--color-content-tertiary-dark);
  --color-content-paragraph-primary: var(--color-content-paragraph-primary-dark);
  --color-content-paragraph-secondary: var(--color-content-paragraph-secondary-dark);
  --color-content-inverted-primary: var(--color-content-inverted-primary-dark);
  --color-content-inverted-secondary: var(--color-content-inverted-secondary-dark);
  --color-content-background-primary: var(--color-content-background-primary-dark);
  --color-content-background-secondary: var(--color-content-background-secondary-dark);
  --color-content-background-tertiary: var(--color-content-background-tertiary-dark);

  --color-background-alternate: var(--color-background-alternate-dark);
  --color-background-default: var(--color-background-default-dark);
  --color-background-glassy-a: var(--color-background-glassy-a-dark);
  --color-background-glassy-b: var(--color-background-glassy-b-dark);
  --color-background-glassy-c: var(--color-background-glassy-c-dark);
  --color-background-glassy-d: var(--color-background-glassy-d-dark);
  --color-background-glassy-inverted-a: var(--color-background-glassy-inverted-a-dark);
  --color-background-glassy-inverted-b: var(--color-background-glassy-inverted-b-dark);
  --color-background-glassy-inverted-c: var(--color-background-glassy-inverted-c-dark);
  --color-background-glassy-inverted-d: var(--color-background-glassy-inverted-d-dark);
  --color-background-overlay: var(--color-background-overlay-dark);
  --color-background-btn: var(--color-background-btn-dark);
  --color-background-btn-hover: var(--color-background-btn-hover-dark);
  --color-background-violet: var(--color-background-violet-dark);
  --color-background-pink: var(--color-background-pink-dark);
  --color-accent-alert: var(--color-accent-alert-dark);
  --color-accent-info: var(--color-accent-info-dark);
  --color-accent-success: var(--color-accent-success-dark);
  --color-accent-warning: var(--color-accent-warning-dark);
  --color-accent-promo: var(--color-accent-promo-dark);
  --color-accent-promo-background: var(--color-accent-promo-background-dark);

  --color-card-border: var(--color-card-border-dark);
  --color-card: var(--color-card-dark);
  --color-card-glassy: var(--color-card-glassy-dark);
  --border: var(--border-dark);
  
  --color-quiz: var(--color-quiz-dark);
  --shadow-solid-small: var(--shadow-solid-small-dark);
}
