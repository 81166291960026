@import 'styles/index';

.card {
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100%;
  height: set-calc(276);
  padding: set-calc(40);

  border-radius: set-calc(40);
  background: var(--color-accent-promo-background);

  @include set-media(md) {
    height: set-calc(620);

    padding: set-calc(24);
    border-radius: set-calc(32);

    width: 100%;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: set-calc(651);
  height: 100%;

  @include set-media(md) {
    justify-content: flex-start;
    gap: set-calc(32);
    width: 100%;
  }
}

.title {
  @include font-40-header;
  font-family: $font-family-medium;
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include set-media(md) {
    width: 100%;
  }
}

.actions__btn {
  @include btn;
  @include button-56;
  @include button-special;

  min-width: set-calc(320);

  z-index: 100;

  @include set-media(md) {
    width: max-content;
    min-width: unset;
  }
}

.image {
  position: absolute;
  bottom: 0;
  right: 0;

  width: set-calc(784/2);
  height: set-calc(552/2);

  img {
    width: 100%;
    height: auto;
  }

  @include set-media(md) {
    width: set-calc(796/2);
    height: set-calc(772/2);
  }
}