@import 'styles/index';

.header {
  @include header;

  margin-right: set-calc(80);

  @include set-media(md) {
    margin-right: set-calc(0);
  }
}

.list {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: set-calc($gap-8);

  @include set-media(md) {
    position: relative;

    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .note {
    display: flex;
    flex-wrap: wrap;
    gap: set-calc(8);
    padding: set-calc(24) set-calc(16) 0;

    @include set-media(md) {
      padding: set-calc(16) set-calc(8) 0;
    }

    span {
      @include font-18-ui;

      color: var(--color-content-primary);
    }

    a {
      @include key-link;
      @include font-18-ui;
    }
  }
}
