@import 'styles/index';

.title_subscription {
  position: relative;

  @include font-14-ui;
  font-family: $font-family-medium;
  text-align: center;

  .star_14 {
    @include icon-size(20);

    position: absolute;
    top: set-calc(-7);
    right: set-calc(-19.5);
  }
}

.title_discount {
  margin-bottom: set-calc(14);

  @include set-typography(70, 66, 500);
  font-family: $font-family-bold;
  text-align: center;
}

.timer {
  display: flex;
  gap: set-calc(14);

  margin: set-calc(10) 0;
}

.timer__item {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: set-calc(46);
  height: set-calc(46);

  padding: set-calc(10);
  border-radius: set-calc($border-radius-8);
  background-color: var(--color-background-glassy-inverted-d);

  @include font-18-ui;
  color: var(--color-content-primary);
  font-family: $font-family-medium;
  text-align: center;

  &:not(:last-child)::after {
    position: absolute;
    content: ":";

    right: set-calc(-9);

    @include font-18-ui;
    color: var(--color-content-primary);
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: set-calc(8);

  width: 100%;
  margin: set-calc(22) 0 set-calc(30);

  opacity: 0.8;

  @include set-media(md) {
    margin: set-calc(14) 0 set-calc(22);
  }
}

.list__item {
  display: flex;
  justify-content: center;
  align-items: center;

  gap: set-calc(4);
}

.list__item__icon {
  @include icon-size(14);
}

.list__item__text {
  @include set-typography(16, 20, 500);
  text-align: center;
  font-family: $font-family-medium;
}

.discountDescription {
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: set-calc(4);

  @include font-16-ui;

  span:first-child {
    color: var(--color-accent-promo);
    text-align: center;

    text-decoration: line-through;
  }

  span:last-child {
    color: var(--color-accent-promo);
    text-align: center;

    font-family: $font-family-bold;
  }
}

.popup {
  --bg-modal-content: var(--color-accent-promo-background);
}
