@import 'styles/index';

@import 'styles/index';

.__ {
  position: relative;
  margin: set-calc(60) 0 set-calc(40);

  width: set-calc(780);
  left: set-calc(-40);

  @include set-media(md) {
    margin: set-calc(30) 0 set-calc(30);
    width: 100%;
    left: 0;
  };
}
.textImportantWrapper {
  position: relative;
  
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  padding: set-calc(40);
  border-radius: set-calc($border-radius-40);
  border: 1px solid var(--border);
  background-color: var(--color-background-glassy-a);

  @include font-20-article;
  font-family: $font-family-article;
  color: var(--color-content-primary);

  overflow-wrap: break-word;

  p,
  span {
    @include font-20-article;
    font-family: $font-family-article;
    color: var(--color-content-paragraph-primary);
  }

  @include set-media(md) {
    padding: set-calc(16);
    border-radius: set-calc($border-radius-20);
  }
}

.textImportantWrapper > *:not(:last-child) {
  margin-top: set-calc(20);
  margin-bottom: set-calc(16);
}

.textImportantWrapper > *:first-child {
  margin-top: 0;
}
