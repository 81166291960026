* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

@font-face {
  font-weight: 400;
  font-family: Montserrat-Light;
  src: url("../assets/fonts/Montserrat.ttf");
}

@font-face {
  font-family: Montserrat-Normal;
  font-weight: 500;
  src: url("../assets/fonts/Montserrat.ttf");
}

@font-face {
  font-family: Montserrat-Medium;
  font-weight: 600;
  src: url("../assets/fonts//Montserrat.ttf");
}

@font-face {
  font-family: Montserrat-Bold;
  font-weight: 700;
  src: url("../assets/fonts/Montserrat.ttf");
}

@font-face {
  font-weight: 280;
  font-family: Commissioner-Light;
  src: url("../assets/fonts/Commissioner.ttf");
}

@font-face {
  font-weight: 380;
  font-family: Commissioner;
  src: url("../assets/fonts/Commissioner.ttf");
}

@font-face {
  font-weight: 580;
  font-family: Commissioner-Medium;
  src: url("../assets/fonts/Commissioner.ttf");
}

@font-face {
  font-weight: 700;
  font-family: Commissioner-Bold;
  src: url("../assets/fonts/Commissioner.ttf");
}

html {
  font-family: $font-family-normal, "Segoe UI", Roboto, "Helvetica Neue" ,Arial, sans-serif;
  font-size: set-calc(18);
  line-height: set-calc(28);
  font-weight: 500;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    background: transparent;
    width: 0;
  }
}

body,
h1,
h2,
h3,
p,
ul,
button {
  margin: 0;
  padding: 0;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  margin: 0;
  padding: 0;

  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;

  overflow-x: hidden;

  color: var(--color-content-primary);
  text-rendering: optimizeLegibility;

  touch-action: manipulation;
  -webkit-user-drag: none;
  -ms-content-zooming: none;

  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none
}

h1 {
  @include set-typography(88, 96, 500);
  letter-spacing: -.01rem;

  @include set-media(md) {
    @include set-typography(48, 52, 500)
  }
}

h2 {
  @include set-typography(56, 61, 500);
  letter-spacing: -.01rem;

  @include set-media(md) {
    @include set-typography(40, 44, 500)
  }
}

h3 {
  @include set-typography(48, 55.2, 500);

  @include set-media(md) {
    @include set-typography(32, 36.8, 500);
  }
}

p {
  @include set-typography(24, 32, 500);

  @include set-media(md) {
    @include set-typography(20, 28, 500)
  }
}

ul {
  list-style: none;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
  unicode-bidi: isolate;
}

input,
input::placeholder {
  color: inherit;
  font-family: $font-family-normal;
}

input:-webkit-autofill,
input:-webkit-autofill:focus,
:-webkit-autofill:hover {
  transition: background-color 0s 600000s, color 0s 600000s !important;

  font-family: $font-family-normal!important;
}

html input[type=button],input[type=reset],input[type=submit] {
  cursor: pointer;
  -webkit-appearance: button;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  transition: background-color $d-hover, color $d-hover, opacity $d-hover;
  -o-transition: background-color $d-hover, color $d-hover, opacity $d-hover;
  -moz-transition: background-color $d-hover, color $d-hover, opacity $d-hover;
  -ms-transition: background-color $d-hover, color $d-hover, opacity $d-hover;
  -webkit-: background-color $d-hover, color $d-hover, opacity $d-hover;
}

button {
  padding: 0;
  border: 0;
  background: none;
  outline: none;
  border-radius: 0;

  font-family: inherit;
  font-style: inherit;
  font-variant: inherit;
  line-height: 1;
  text-transform: none;

  -webkit-appearance: button;

  cursor: pointer;
  transition: color $d-hover,background-color $d-hover,opacity $d-hover;
  -o-transition: color $d-hover,background-color $d-hover,opacity $d-hover;
  -moz-transition: color $d-hover,background-color $d-hover,opacity $d-hover;
  -ms-transition: color $d-hover,background-color $d-hover,opacity $d-hover;
  -webkit-transition: color $d-hover,background-color $d-hover,opacity $d-hover;

  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

button::-moz-focus-inner,input::-moz-focus-inner {
  padding: 0;
  border: 0
}

input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button {
  height: auto
}

input[type=search]::-webkit-search-cancel-button,input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none
}

table {
  border-collapse: collapse;
  border-spacing: 0
}

td,th {
  padding: 0
}

s {
  position: relative;
  text-decoration: none;
  white-space: nowrap;
}

.isActive {
  color: var(--primary-color) !important;
}
