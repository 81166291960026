@import 'styles/index';


.catalogueWrapper {
  grid-column: span 12;

  position: relative;

  display: flex;
  flex-direction: column;
}

.section__title {
  @include section-title;
}

.btnGroup {
  position: absolute;
  top: set-calc(-22);
  right: 0;

  display: flex;
  padding: set-calc(4);
  align-items: center;
  gap: set-calc(4);

  width: max-content;

  border-radius: set-calc(40);
  background: var(--color-card);

  .btn {
    @include btn;
    @include icon-button-40;
    @include icon-button-secondary;
    
    width: set-calc(40);

    border-radius: set-calc(20);
  }

  @include set-media(md) {
    display: none;
  }
}

.group {
  display: flex;
  gap: set-calc(4);
  overflow: hidden;

  padding: set-calc(10) 0 set-calc(40) set-calc(16);

  @include set-media(md) {
    position: relative;
    
    display: flex;
    flex-flow: row nowrap;
    gap: set-calc(2);

    left: set-calc(-$p-page-mob-m);
    width: set-calc(2 * $p-page-mob-m, true, 100%);
    padding: 0 set-calc($p-page-mob-m) set-calc(40);

    overflow-x: scroll;
  }
}