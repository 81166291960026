@import 'styles/index';

.wrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: set-calc(280);
  min-width: set-calc(250);
  max-width: set-calc(280);
  gap: set-calc(16);

  background-color: transparent;
  transform-origin: center center;

  &:hover {
    cursor: pointer;
    .background {
      // transform: scale(1.02);
      box-shadow: $shadow-medium-hovered;
    }

    .btn {
      opacity: 0.9;
    }
  }
  
  @include set-media(md) {
    &:hover {
      .background {
        box-shadow: none!important;
        transform: scale(1,1)!important;
      }

      .btn {
        opacity: 1;
      }
    }
  }

  &_horizontal {

    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-direction: row-reverse;

    width: 100%;
    min-width: set-calc(320);
    max-width: set-calc(440);
  
    gap: set-calc(8);
    padding: set-calc(24) set-calc(16) set-calc(32);

    @include set-media(md) {
      width: 100%;
    }
  
    .icon {
      position: relative;
      background: transparent;

      padding: 0;
      height: unset;
      width: max-content;

      .course {
        width: set-calc(88);
        height: set-calc(88);

        z-index: 2;
      
        img {
          width: 100%;
          height: auto;
          z-index: 2;
        }
      }
    }
  
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: set-calc(6);

      padding: 0;
    }
  
    .title {
      justify-content: start;
      text-align: start;

      @include set-typography(20, 24, 500);
      font-family: $font-family-medium;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      word-break: break-word;
      overflow: hidden;
    
      -webkit-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
    }
  
    .description {
      justify-content: start;
      text-align: start;

      @include set-typography(12, 16, 500);
      color: var(--color-content-tertiary);
    }
  
    .meta {
      justify-content: flex-start;
      
      @include set-typography(12, 16, 500);
      color: var(--color-content-tertiary);
    }
  }
}

.icon {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;


  width: 100%;
  height: set-calc(200);
  padding: set-calc(16);
  background: var(--color-background-violet);

  border-radius: set-calc(32) set-calc(32) 0 0;

  .course {
    width: set-calc(132);
    height: set-calc(132);

    z-index: 2;
  
    img {
      width: 100%;
      height: auto;
      z-index: 2;
    }
  }
  
  @include set-media(md) {
    position: relative;
    margin-bottom: 0;

    border-radius: set-calc(20) set-calc(20) 0 0;

    .course {
      position: relative;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: set-calc(10);
  flex: 1 1;

  padding: 0 set-calc(40) set-calc(40);

  @include set-media(md) {
    padding: 0 set-calc(32) set-calc(32);
  }
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  @include set-typography(20, 24, 500);
  color: var(--color-content-primary);
  font-family: $font-family-medium;
  text-align: center;

  @include set-media(md) {

  }
}

.description {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  @include set-typography(12, 16, 500);
  color: var(--color-content-tertiary);
  text-align: center;
  
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-word;
  overflow: hidden;

  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;

  @include set-media(md) {

  }
}

.meta {
  position: relative;
  
  display: flex;
  justify-content: center;
  width: 100%;

  @include set-typography(12, 16, 500);
  color: var(--color-content-tertiary);

  p {
    position: relative;

    font-size: inherit;
    line-height: inherit;
    color: inherit;
    text-align: left;
  }

  p:not(:last-child) {
    margin-right: set-calc(20);

    &::after {
      content: "";
      position: absolute;
      right: set-calc(-10);
      top: 50%;
    
      width: set-calc(3);
      height: set-calc(3);
      border-radius: 50%;
      background-color: var(--color-content-tertiary);
      transform: translate3d(0, -50%, 0);
    }
  }
}

.background {
  position: absolute;
  top: 0;
  left: 0;

  z-index: -1;

  width: 100%;
  height: 100%;
  
  transform-origin: center center;
  overflow: hidden;

  border-radius: set-calc($border-radius-32);
  background: var(--color-card);

  box-shadow: $shadow-medium-default;

  transition: transform $d-hover ease, box-shadow $d-hover ease;

  @include set-media(md) {
    border-radius: set-calc($border-radius-20);
  }
}