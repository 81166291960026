@import 'styles/index';

.section__title {
  @include section-title;
}

.wrapper {
  display: flex;
  gap: set-calc(4);
  overflow: hidden;

  padding: set-calc(10) 0 set-calc(40) set-calc(16);

  @include set-media(md) {
    position: relative;
    
    display: flex;
    flex-flow: row nowrap;
    gap: set-calc(2);

    left: set-calc(-$p-page-mob-m);
    width: set-calc(2 * $p-page-mob-m, true, 100%);
    padding: 0 set-calc($p-page-mob-m) set-calc(40);

    overflow-x: scroll;
  }
}