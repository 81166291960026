@import 'styles/index';

.section__title {
  @include section-title;
}

.wrapper {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: set-calc(20) set-calc(4);

  @include set-media(md) {
    display: flex;
    flex-direction: column;
    gap: set-calc(8);
  }
}