
@import 'styles/index';

.form {
  position: relative;

  display: flex;
  flex-direction: column;
  width: 100%;

  @include set-media(md) {
    margin-top: set-calc(16);
  }
}

.input {
  @include input;
  @include input-large;

  width: 100%;
}

.submitButton {
  @include btn;
  @include button-56;
  @include button-primary;

  width: 100%;
  margin-top: set-calc(12);

  @include set-media(md) {
    margin-top: set-calc(8);
  }
}

.isSuccess {
  pointer-events: none;
  background-color: var(--color-accent-success);
  color: var(--color-content-inverted-primary);
}

.Error {
  color: var(--color-accent-alert);
  @include font-14-ui;

  margin-top: set-calc(6);
  margin-left: set-calc(4);
}
