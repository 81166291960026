@import 'styles/index';
.wrapper {
  position: relative;
}

.card {
  position: relative;

  display: flex;
  flex-direction: column;

  padding: set-calc(40);

  background: var(--primary-color);
  border-radius: set-calc($border-radius-40);

  @include set-media(md) {
    padding: set-calc(24);
    border-radius: set-calc($border-radius-32);
  }
}

.title {
  @include  font-40-header;
  font-family: $font-family-medium;
  color: $color-content-inverted-primary-light;

  margin-bottom: set-calc(20);

  width: 70%;

  @include set-media(md) {
    width: 100%;
    margin-bottom: set-calc(16);
  }
}

.desc {
  @include font-18-paragraph;
  color: $color-content-inverted-primary-light;
  width: 50%;

  margin-bottom: set-calc(40);

  @include set-media(md) {
    width: 100%;
    margin-bottom: set-calc(24);
  }
}

.actions {
  display: flex;

  @include set-media(md) {
    flex-direction: row-reverse;
    width: 100%;
  }
}

.actionsBtn {
  @include btn;
  @include button-56;
  @include button-outline($color-content-inverted-primary-light, var(--primary-color));

  @include set-media(md) {
    width: 100%;
  }
}