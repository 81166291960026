.P {
  margin: 1rem 0;
}

.H3 {
  margin: 1rem 0;
}

.UL {
  list-style-type: disc;
  margin-left: 2rem;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
