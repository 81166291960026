@import 'styles/index';

.__ {
  position: relative;
  color: var(--color-content-primary);
  margin: set-calc(20) 0 set-calc(40);

  // width: set-calc(780);
  // left: set-calc(-40);

  @include set-media(md) {
    margin: set-calc(30) 0 set-calc(30);
    width: 100%;
    left: set-calc(0);
  };
}

.quoteWrapper {
  padding: set-calc(40);
  border: set-calc(1) solid var(--border);
  border-radius: set-calc($border-radius-40);

  display: flex;
  flex-direction: column;

  @include set-media(md) {
    padding: set-calc(16);
    border-radius: set-calc($border-radius-20);
  }
}

.quoteWrapper > *:not(:last-child) {
  margin: set-calc(20) 0 set-calc(16);

  @include set-media(md) {
    margin: set-calc(20) 0 set-calc(16);
  }
}

.quoteWrapper > *:first-child {
  margin-top: 0;
}

.quoteWrapper > * {

  @include font-20-article;
  font-family: $font-family-article;
  color: var(--color-content-paragraph-primary);
  
  p,
  span {
    @include font-20-article;
    font-family: $font-family-article;
    color: var(--color-content-paragraph-primary);
  }
}