@import 'styles/index';

.section {
  display: block;
  position: relative;
}

.section:not(:last-child) {
  margin-bottom: set-calc(120);

  @include set-media(md) {
    margin-bottom: set-calc(64);
  }
}

.section:has([data-bcalternate]) {
  margin-bottom: 0;

  @include is-beige;
  background-color: var(--color-background-alternate);

  display: flex;
  flex-direction: column;

  padding: 0 set-calc($p-page-desk-l) set-calc(120);

  @include set-media(md) {
    padding: 0 set-calc($p-page-mob-s) set-calc(64);
  }
}

.section:has([data-bcred]) {
  margin-bottom: 0;

  @include is-beige;
  background-color: var(--color-red);

  display: flex;
  flex-direction: column;
  
  color: $color-content-inverted-primary-light;
  padding: set-calc(120) set-calc($p-page-desk-l);

  @include set-media(md) {
    padding: set-calc(64) 0 set-calc(64) set-calc($p-page-mob-s);
  }
}

.section:has([data-bcpink]) {
  margin-bottom: 0;

  @include is-beige;
  background-color: var(--color-background-pink);

  display: flex;
  flex-direction: column;
  
  padding: set-calc(120) set-calc($p-page-desk-l);

  @include set-media(md) {
    padding: set-calc(64) set-calc($p-page-mob-s);
  }
}

.section:not(:has([data-bcalternate])) + .section:has([data-bcalternate]),
.section:has([data-bcred]) + .section,
.section:has([data-bcpink]) + .section {
  padding-top: set-calc(120);

  @include set-media(md) {
    padding-top: set-calc(64);
  }
}

.section:has([data-bcalternate]) + .section:not(:has([data-bcalternate])) {
  margin-top: set-calc(120);

  @include set-media(md) {
    margin-top: set-calc(64);
  }
}

.section:has([data-bcalternate]) + .section:has([data-bcred]),
.section:has([data-bcalternate]) + .section:has([data-bcpink]) {
  margin-top: 0;

  @include set-media(md) {
    margin-top: 0;
  }
}

.section:has([data-bannerstart]) {

  @include set-media(md) {
    width: set-calc(16, true, 100%);
    left: set-calc(-8);
    
    padding: 0;
    margin: 0;
  }
}

.section:has([data-faq]) {
  flex-direction: row;

  @include set-media(md) {
   flex-direction: column;
  }
}

.section:last-child {
  @include set-media(md) {
    margin-bottom: 0;
    padding-bottom: set-calc(24);
  }
}