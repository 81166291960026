@import 'styles/index';

.card {
  @include is-grid-12;

  position: relative;

  gap: set-calc(40);

  padding: 0 set-calc(40) set-calc(40);
  background-color: var(--color-background-default);
  border-radius: set-calc($border-radius-40);
  border: 1px solid var(--color-card-border);

  @include set-media(md) {
    display: none;
  }
}

.card__image {
  grid-column: 8 / -1;
  grid-row: span 2;

  display: flex;
  justify-content: center;
  align-items: center;

  width: set-calc(40, true, 100%);
  height: set-calc(40, true, 100%);

  border-radius: 0 set-calc(40) set-calc(40) 0;
  background: var(--color-background-violet);

  img {
    width: set-calc(300);
  }
}

.card__content {
  grid-column: span 7;

  display: flex;
  flex-direction: column;
  flex: 1 1;

  gap: set-calc(16);

  padding-top: set-calc(40);
}

.title {
  @include font-40-header;
  font-family: $font-family-bold;
}

.description {
  @include font-18-paragraph;
}

.metaData {
  display: flex;
  flex-wrap: wrap;

  gap: set-calc(6);

  padding-top: set-calc(8);
}

.metaData__item {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 set-calc(12);
  background-color: var(--color-background-alternate);
  height: set-calc(36);
  border-radius: set-calc( calc(36 / 2));

  color: var(--color-content-paragraph-secondary);
}

.metaData__text {
  @include font-14-ui;
    color: inherit;
    white-space: nowrap;
  }

.actions {
  grid-column: span 7;

  display: flex;
}

.actionsBtn {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  flex-grow: 1;
}

.currentLessonButton {
  @include btn;
  @include button-48;
  @include button-black;

  min-width: set-calc(305);
}