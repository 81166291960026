@import 'styles/index';

.imgWrapper {
  @include icon-size(200);
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin: set-calc(30) 0 set-calc(20);

  @include set-media(md) {
    @include icon-size(120);
    margin: set-calc(20) 0 set-calc(10);
  }
}
