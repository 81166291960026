@import 'styles/index';

.trainerContent {
  position: relative;
  display: flex;
  min-width: 0;
  height: -moz-max-content;
  min-height: 100vh;
}

.theoryPage {
  display: flex;
  align-items: center;
  width: 100%;

  flex: 1 1 100%;
  min-width: 0;
}

.__ {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  height: -moz-max-content;
  height: max-content;
  min-height: 100vh;

  &[data-footer="true"] {
    justify-content: space-between;
  }

  &[data-footer="false"] {
    justify-content: start;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.WEBWrapper {
  padding: set-calc(16) set-calc($p-page-desk-l) 0;

  @include set-media(md) {
    padding: set-calc(8) set-calc($p-page-mob-s) 0;
  }
}

.LMSWrapper {
  padding: 0 set-calc($p-page-desk-l);

  @include set-media(md) {
    padding: 0 set-calc($p-page-mob-m);
  }
}

.FallbackWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: set-calc(16);
  background-color: transparent;

  padding: 0 set-calc($p-page-desk-l);

  @include set-media(md) {
    padding: 0 set-calc($p-page-mob-l);
  }
}