@import 'styles/index';

.wrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: set-calc(280);
  height: set-calc(464);

  border-radius: set-calc($border-radius-32);
  background: var(--color-background-alternate);

  padding: set-calc(28);
  gap: set-calc(24);

  cursor: pointer;

  &:hover {
    .btn {
      background-color: var(--color-content-background-primary);
      color: var(--color-content-inverted-primary);
    }
  }

  @include set-media(md) {
    min-width: set-calc(280);
    &:hover {
      .btn {
        background-color: transparent;
        color: var(--color-content-primary);
      }
    }
  }
}

.icon {
  position: relative;

  padding: set-calc(16);
  width: set-calc(200);
  height: set-calc(200);

  img {
    width: 100%;
    height: auto;
    z-index: 2;
  }
}

.content {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: set-calc(12);
}

.title {
  display: flex;
  width: 100%;

  @include set-typography(20, 26, 500);
  font-family: $font-family-medium;
  color: var(--color-content-primary);
  text-align: center;
}

.meta {
  position: relative;
  
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  p {
    position: relative;

    @include font-14-ui;
    color: var(--color-content-secondary);
    text-align: center;
  }

  p:not(:last-child) {
    margin-right: set-calc(20);

    &::after {
      content: "";
      position: absolute;
      right: set-calc(-10);
      top: 50%;
    
      width: set-calc(3);
      height: set-calc(3);
      border-radius: 50%;
      background-color: var(--color-content-secondary);
      transform: translate3d(0, -50%, 0);
    }
  }
}

.btn {
  @include btn;
  @include button-48;
  @include button-outline(var(--color-content-primary));

  margin-top: auto;
  width: 100%;
}