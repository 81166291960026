@import 'styles/index';

.card {
  grid-column: span 8;
  position: relative;

  display: flex;
  flex-direction: column;
  gap: set-calc(40);

  padding: set-calc(40);
  border-radius: set-calc($border-radius-32);
  border: 1px solid var(--color-card-border);
  background: var(--color-card);

  @include set-media(md) {
    gap: set-calc(16);

    padding: set-calc(24);

  }
}

.content {
  display: flex;
  padding-right: set-calc(198);
  flex-direction: column;
  justify-content: center;
  gap: set-calc(16);

  &__title {
    @include font-36-header;
    font-family: $font-family-bold;
  }

  &__description {
    color: var(--color-content-paragraph-primary);
    @include font-18-paragraph;
  }

  @include set-media(md) {
    gap: set-calc(12);
    padding-right: 0;
  }
}

.headerImage {
  position: absolute;
  right: set-calc(24);
  top: set-calc(24);

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: set-calc(80);
  padding: set-calc(16);
  background: var(--color-background-glassy-a);

  img {
    width: set-calc(160-32);
  }
  
  @include set-media(md) {
    position: relative;
    right: unset;
    top: unset;

    width: max-content;
    padding: set-calc(12);

    img {
      width: set-calc(120-24);
      aspect-ratio: auto 1;
    }
  }
}

.meta {
  position: relative;

  display: flex;
  align-items: center;
  gap: set-calc(6);

  padding-top: set-calc(8);

  @include font-16-ui;

  p {
    position: relative;

    line-height: inherit;
    font-size: inherit;
    font-family: inherit;
  }

  p:not(:last-child) {
    text-align: center;
    margin-right: set-calc(8);
  }

  p:not(:last-child)::after {
    content: "";
    position: absolute;
    right: set-calc(-8);
    top: 50%;
  
    width: set-calc(3);
    height: set-calc(3);

    border-radius: 50%;
    background-color: var(--color-content-primary);
    transform: translate3d(0, -50%, 0);
  }
}

.actions {
  display: flex;
  gap: set-calc(4);

  &__btn {
    @include btn;
    @include button-48;
    @include button-primary;
  
    min-width: set-calc(305);
  
    @include set-media(md) {
      width: 100%;
    }
  }

  @include set-media(md) {
    padding-top: set-calc(12);
  }
}

.currentLessonButton {
  @include btn;
  @include button-48;
  @include button-black;

  min-width: set-calc(305);

  @include set-media(md) {
    width: 100%;
  }
}

.nextLesson {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  gap: set-calc(6);

  padding-left: set-calc(12);

  &__subTitle {
    color: var(--color-content-tertiary);

    @include font-14-ui;
    text-transform: uppercase;
  }

  &__title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;

    overflow: hidden;
    color: color-content-primary;
    text-overflow: ellipsis;

    @include font-14-ui;
  }

  @include set-media(md) {
    display: none;
  }
}

.emptyCard {
  grid-column: span 8;
  position: relative;

  display: flex;
  flex-direction: column;
  gap: set-calc(40);

  padding: set-calc(24);
  border-radius: set-calc($border-radius-32);
  border: 1px solid var(--border);

  &__icon {
    position: absolute;
    right: set-calc(24);
    top: set-calc(24);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: set-calc(88);
    height: set-calc(88);

    svg {
      width: 100%;
      height: 100%;
    }

    @include set-media(md) {
      position: relative;
      right: unset;
      top: unset;
  
      width: max-content;
    }
  }

  @include set-media(md) {
    gap: set-calc(16);
  }
}