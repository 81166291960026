@import 'styles/index';

.__ {
  @include is-grid-3;
  grid-gap: set-calc($gap-8);

  padding: set-calc(24);
  margin: set-calc(8) auto 0;

  background-color: var(--color-card);
  border: 1px solid var(--color-card-border);
  border-radius: set-calc($border-radius-20);

  @include set-media(md) {
    margin: set-calc(6) auto 0;
    row-gap: set-calc(20);
    padding: set-calc(16);
  }
}

.item {
  display: flex;
  justify-content: stretch;
  align-items: center;
  gap: set-calc(16);

  .itemSvg {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: set-calc(12);

    border-radius: set-calc($border-radius-12);
    background-color: var(--color-background-glassy-b);

    svg {
      width: set-calc(32);
      height: set-calc(32);
  
     path {
        fill: var(--primary-color);
      }
    }

    @include set-media(md) {
      padding: set-calc(10);

      svg {
        width: set-calc(30);
        height: set-calc(30);
      }
    }
  }

  @include set-media(md) {
    gap: set-calc(16);
  }
}

.itemText {
  @include font-16-paragraph;
  color: var(--color-content-paragraph-primary);
  max-width: set-calc(320);
}