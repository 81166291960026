
._ {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: set-calc(16);
  // background-color: transparent;

  // padding: 0 set-calc($padding-x-desk-page);

  // @include set-media(md) {
  //   padding: 0 set-calc($padding-x-mob-page);
  // }
}
