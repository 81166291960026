@import 'styles/index';

.agreement {
  align-self: flex-end;

  margin-top: set-calc(6);
  margin-right: set-calc(4);

  @include font-14-ui;
}
