@import 'styles/index';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;

  flex: 1 1;
  padding: 0 set-calc($p-page-desk-l) set-calc(40);

  @include set-media(md) {
    padding: set-calc(24) set-calc($p-page-mob-l) set-calc(16);
  }
}

.card {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  width: 100%;
  padding: set-calc(40);

  border-radius: set-calc(40);
  border: 1px solid var(--border);

  @include set-media(md) {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: set-calc(24);

    padding: 0 0;
    border-radius: 0;
    border: none;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: set-calc(12);
}

.creators {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: set-calc(8);

  @include set-media(md) {
    display: none;
  }
}

.person {
  display: flex;
  gap: set-calc(6);
  align-items: baseline;

  &__title {
    @include font-14-ui;
    color: var(--color-content-secondary);
  }
  
  &__name {
    @include s-hoverable;

    @include font-14-ui;
    color: var(--color-content-secondary);
  }
}

.copyright {
  @include font-12-ui;
  color: var(--color-background-glassy-d);

  @include set-media(md) {
    @include font-14-ui;
  }
}

.documents {
  display: flex;
  gap: set-calc(6);

  a {
    @include s-hoverable;
    @include font-12-ui;
    color: var(--color-background-glassy-d);

    @include set-media(md) {
      @include font-14-ui;
    }
  }

  @include set-media(md) {
    flex-direction: column;
    gap: set-calc(8);
  }
}

.contacts {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: set-calc(8);

  @include set-media(md) {
    justify-content: flex-start;
    gap: set-calc(10);
  }
}

.social {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: set-calc(6);

  &__link {
    @include icon-button-40;
    @include icon-button-secondary;

    width: set-calc(40);

    @include set-media(md) {
      @include icon-button-primary;
    }
  }

  @include set-media(md) {
    justify-content: flex-start;


    width: 100%;
    margin-bottom: set-calc(24);
  }
}

.email {
  width: 100%;

  @include font-14-ui;
  color: var(--color-content-primary);
  text-align: end;

  @include set-media(md) {
    text-align: start;

    @include font-32-header;
  }
}

.emailComment {
  width: 100%;

  @include font-12-ui;
  color: var(--color-background-glassy-d);
  text-align: end;

  @include set-media(md) {
    @include font-14-ui;
    text-align: start;
    color: var(--color-content-secondary);
  }
}
