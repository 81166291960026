@import 'styles/index';

.header {
  @include header;

  span:nth-child(2n) {
    color: var(--color-background-glassy-d);
  }
}

.list {
  display: flex;
  gap: set-calc(4);

  @include set-media(md) {
    flex-direction: column;
  }
}

.column {
  display: flex;
  flex-direction: column;

  width: calc(33.3% - set-calc(16));
  gap: set-calc(4);
}