@import 'styles/index';

.wrapper {
  position: sticky;
  position: -webkit-sticky; 
  top: 0;
  z-index: 500;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.desk {
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  border-bottom: 1px solid var(--color-card-border);
  background-color: transparent;
}

.desk_WEB {
  padding: set-calc(12) set-calc(calc($p-page-desk-l));

  backdrop-filter: blur(set-calc($blur-16));
  background-color: var(--color-card-glassy);

  .menu__section {
    margin-right: auto;
  }

  .btn_login,
  .btn_start,
  .nav {
    @include button-48;
  }

  .dropdown {
    @include dropdown-48;
    @include dropdown-primary;
  }

  @include set-media(md) {
    padding: set-calc(12) set-calc($p-page-mob-s);
    border-bottom: 0.5px solid var(--border);

    .btn_login,
    .btn_start,
    .nav {
      @include button-40;
    }

    .dropdown {
      @include dropdown-40;
    }
  }
}

.desk_LMS {
  padding: set-calc(12) set-calc(calc($p-page-desk-s));
  backdrop-filter: blur(set-calc(0));
  border-bottom: 1px solid transparent;

  .menu__section {
    margin-left: auto;
  }

  .btn_login,
  .btn_buy,
  .btn_start,
  .nav {
    @include button-40;
  }

  .logo {
    display: none;

    @include set-media(md) {
      display: flex;
      height: set-calc(40);
    }
  }

  .dropdown {
    @include dropdown-40;
    @include dropdown-secondary;
  }

  @include set-media(md) {
    padding: set-calc(12) set-calc($p-page-mob-s);

    backdrop-filter: blur(set-calc($blur-16));
    background-color: var(--color-card-glassy);
    border-bottom: 0.5px solid var(--border);
  }
}

.logo {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: set-calc(2);

  height: set-calc(48);
  @include rounded(48);

  padding: 0 set-calc(16);
  background: var(--color-background-glassy-b, rgba(0, 0, 0, 0.05));
  
  cursor: pointer;

  &__name {
    @include set-typography(20, 20, 500);
    font-family: $font-family-normal;
    font-feature-settings: 'ss01' on;
    align-self: center;

    @include set-media(md) {
      @include set-typography(24, 24, 500);
    }
  }

  &__icon {
    display: none;
  }

  @include set-media(md) {
    background: transparent;
  }
}

.menu {
  display: flex;
  align-items: baseline;
  flex: 1 1;

  gap: set-calc(6);

  @include set-media(md) {
    justify-content: space-between;
  }
}

.menu__section {
  position: relative;

  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: set-calc(6);

  margin-left: set-calc(24);

  @include set-media(md) {
    width: 100%;
    justify-content: space-between;
    gap: set-calc(8);
  }

  .dropdown {
    @include dropdown;
  
    @include set-media(md) {
      display: none;
    }
  }

  .nav {
    @include btn;
    @include button-invisible;
  }
}

.menu__btns {
  position: relative;

  display: flex;
  align-items: baseline;
  gap: set-calc(6);

  .btn_start {
    @include btn;
    @include button-primary;
  
    width: max-content;
  }

  .btn_login {
    @include btn;
    @include button-secondary;
  
    width: max-content;
  }
  
  .btn_buy {
    @include btn;
    @include button-primary;
    width: max-content;
  }

  .btn_back {
    display: none;
  
    @include set-media(md) {
      @include icon-button-40;
      @include icon-button-invisible;
      width: set-calc(40);
      margin-right: auto;
    }
  }

  @include set-media(md) {
    flex: 1 1;
    justify-content: end;
  }
}

.menuBtn {
  display: none;

  @include set-media(md) {
    @include icon-size(24);

    display: flex;
    justify-content: center;
  
    padding: set-calc(8) set-calc(8);
    width: set-calc(40);
    height: set-calc(40);
    opacity: 1;
  
    color: var(--color-content-inverted-primary);
    background-color: var(--color-background-btn);
    border-radius: 50%;
  }
}

.isActive {
  color: var(--primary-color) !important;
}

.open {
  z-index: 10000;
}

._hidden {
  // transform: translateY(-100%);

  .desk {
    border-bottom: 1px solid var(--border);
  }

    @include set-media(md) {
      // transform: none;

      // .desk {
      //   transform: none;
      //   transform: translateY(-100%);
      // }
    }
}
