@import 'styles/index';

.menu {
  position: absolute;
  right: 0;
  left: auto;

  z-index: 1000;

  display: flex;
  align-items: flex-start;
  flex: 1 0;

  margin-top: set-calc(8);
  width: set-calc(320);
  max-height: 80vh;

  padding: set-calc(6);
  border-radius: set-calc($border-radius-16);
  background:  var(--color-card);
  border: 1px solid var(--border);

  transition: background-color $d-hover ease;
  box-shadow: $shadow-menu;
}

.list {
  position: relative;

  display: flex;
  width: 100%;
  flex-direction: column;
  gap: set-calc(4);

  &__title {
    padding: set-calc(8) set-calc(12);
  
    @include font-14-ui;
    font-family: $font-family-medium;
    color: var(--color-content-secondary);
    text-transform: uppercase;
  }

  &__item {
    position: relative;

    display: flex;
    flex: 1 1;
    gap: set-calc(12);

    @include font-14-ui;
    color: var(--color-content-primary);
    font-family: $font-family-normal;

    padding: set-calc(10) set-calc(12);
  
    border-radius: set-calc($border-radius-10);
    background-color: transparent;
  
    transition: background-color 0.3s;
    cursor: pointer;

    a {
      font-size: inherit;
      line-height: inherit;
      font-family: inherit;
    }
  
    &:hover {
      background-color: var(--color-background-glassy-b);
    }
  }
}

.active {
  background-color: var(--color-background-glassy-a);
}