@import 'styles/index';

.btn {
  @include btn;
  @include button-56;

  &_primary {
    @include button-primary;
    width: 100%;
  }

  &_special {
    @include button-special($color: var(--color-content-inverted-primary), $bc: var(--color-accent-promo));
    width: 100%;
  }

  &_invisible {
    @include button-invisible;
    width: max-content;
  }
}
