@import 'styles/index';

.section__title {
  @include section-title;
}

.list {
  display: flex;
  gap: set-calc(4);

  @include set-media(md) {
    flex-direction: column;
  }
}

.column {
  display: flex;
  flex-direction: column;

  width: calc(33.3% - set-calc(16));
  gap: set-calc(4);
}