@import 'styles/index';

.header {
  @include header;
}

.list {
  display: flex;
  gap: set-calc(4);

  @include set-media(md) {
    flex-direction: column;
    gap: set-calc(4);
  }
}

.item {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  gap:set-calc(20);

  height: set-calc(320);
  padding: set-calc(40);

  border-radius: set-calc($border-radius-40);
  background: var(--color-card, #262626);

  &__image {
    display: none;

    @include set-media(md) {
      position: absolute;
      display: block;
      right: 0;
      bottom: 0;
  
      width: set-calc(226);
      height: set-calc(210);
  
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  &__title {
    @include font-28-header;
    font-family: $font-family-medium;
  }
  
  &__description {
    @include font-18-paragraph;
    color: var(--color-content-secondary);
    gap: set-calc(8);
  
    p {
      font-size: inherit;
      line-height: inherit;
      font-family: inherit;
    }
  }

  @include set-media(md) {
    gap: set-calc(8);
    padding: set-calc(24);

    min-height: set-calc(320);
    width: 100%;

    border-radius: set-calc($border-radius-32);
  }
}

.group {
  display: grid;
  grid-gap: set-calc(4);
  grid-template-columns: 4fr 6fr;
  grid-template-areas: "explain-text explain-image";

  margin-top: set-calc(4);

  @include set-media(md) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.column {
  grid-area: explain-text;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: end;
  gap: set-calc(4);

  overflow: hidden;

  @include set-media(md) {
    gap: set-calc(4);
  }
}

.card {
  position: relative;

  display: flex;
  flex-direction: column;
  width: 100%;
  padding: set-calc(40);

  background-color: var(--color-violet);
  border-radius: set-calc($border-radius-40);
  
  @include font-18-paragraph;
  color: $color-content-primary-dark;
  gap: set-calc(8);

  p {
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;
  }

  @include set-media(md) {
    padding: set-calc(24);
    border-radius: set-calc($border-radius-32);
  }
}

.card:nth-child(2n) {
  max-width: set-calc(410);

  @include set-media(md) {
    max-width: 100%;
  }
}

.author {
  display: flex;

  width: set-calc(396);
  padding: set-calc(16);

  @include font-16-paragraph;
  color: var(--violet, #855BE1);
  text-align: right;

  background-color: transparent;

  @include set-media(md) {
    display: none;
  }
}

.imageCard + .author {
  display: none;

  @include set-media(md) {
    display: flex;

    width: set-calc(248);
    padding: 0 set-calc(8);

    @include font-14-paragraph;
  }
}

.imageCard {
  grid-area: explain-image;
  position: relative;

  display: flex;
  flex: 1 1;

  border-radius: set-calc(40);
  background: var(--color-violet, #855BE1);

  width: 100%;
  height: auto;

  img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: set-calc($border-radius-40);
  }

  @include set-media(md) {
    width: set-calc(255);
    height: set-calc(235);
  }
}