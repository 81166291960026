@import 'styles/index';

.wrapper {
  position: relative;

  padding: set-calc(40);
  border-radius: set-calc($border-radius-40);
  background-color: var(--color-card);
  cursor: pointer;

  display: flex;
  flex-direction: column;
  width: 100%;

  @include set-media(md) {
    width: 100%;

    padding: set-calc(24);
    border-radius: set-calc($border-radius-32);
  }
}

.title {
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: set-calc(16);

  @include font-24-header;
  color: var(--color-content-primary);
  font-family: $font-family-medium;

  padding-bottom: set-calc(20);
  cursor: pointer;

  @include set-media(md) {
    gap: set-calc(8);
    padding-bottom: set-calc(10);

    font-family: $font-family-normal;
  }
}

.arrow {
  @include icon-size(24);

  transition: transform $d-hover ease-in-out;
  color: var(--color-content-primary);

  @include set-media(md) {
    margin-top: set-calc(0);
  }
}

.arrow_expended {
  transform: rotate(180deg);
}

.description {
  height: 0;
  overflow: hidden;
  
  padding-right: set-calc(40);

  @include font-18-paragraph;
  color: var(--color-content-tertiary);

  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;

  transition-property: height, transform, margin;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.4,0.4,0.2,1);

  @include set-media(md) {
    padding-right: set-calc(16);
  }
}

.description_expanded {
  transform: translate3d(0px, -8px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);

  transition-property: height, transform, margin;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.4,0.4,0.2,1);

  @include set-media(md) {
    margin: set-calc(20) 0;
  }
}