@import 'styles/index';

.section__title {
  @include section-title;
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: set-calc(4);

  @include set-media(md) {
    flex-direction: column;
    gap: set-calc(8);
  }
}