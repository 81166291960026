@import 'styles/index';

.contentWrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  gap: set-calc(10);
  @include font-18-paragraph;
  color: var(--color-content-primary);

  padding: set-calc(40);
  width: set-calc(480);

  @include set-media(md) {
    // min-height: 100vh;
    // min-height: -webkit-fill-available;
    // min-height: fill-available;

    padding: set-calc(24);
    width: 100%;
  }
}

.close {
  @include btn;
  @include icon-button-40;
  @include icon-button-invisible;

  width: set-calc(40);

  position: absolute;
  top: set-calc(20);
  right: set-calc(20);

  z-index: 1;


  @include set-media(md) {
    top: 0;
    right: 0;

    height: set-calc(72);
    width: set-calc(72);

    padding: set-calc(24) set-calc(24);
  }
}

.header {
  margin: 0 0 set-calc(30);
}

.title {
  @include set-typography(24, 30, 500);
  font-family: $font-family-medium;

  &_start {
    align-self: start;
    max-width: 92%;
  }

  &_center {
    text-align: center;
  }
}

.title:not(:last-child) {
  margin-bottom: set-calc(24);
  
  @include set-media(md) {
    margin-bottom: set-calc(16);
  }
}
