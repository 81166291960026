@import 'styles/index';

.wrapperShowMore {
  margin: 0 0 set-calc(140);
  padding: set-calc(8) 0 0;

  border-top: set-calc(calc($gap-8 / 2)) solid var(--color-content-background-primary);

  &Btn {
    grid-column: 3 / 4;
    margin-right: 0;
    margin-left: auto;
  }
}

.header {
  padding-bottom: set-calc(32);
  
  @include set-media(md) {
    padding-bottom: set-calc(16);
  }
}

.headerTitle {
  @include font-56-header;
  font-family: $font-family-bold;
  padding: 0 set-calc(16);

  @include set-media(md) {
    padding: 0 set-calc(8);
  }
}

.points {
  @include is-grid-3;
}

.pointsCard {
  display: flex;
  flex-direction: column;

  padding: set-calc(24);

  border-radius: set-calc($border-radius-20);
  background-color: var(--color-card);
  border: 1px solid var(--color-card-border);

  @include set-media(md) {
    min-height: unset;
    padding: set-calc(16);
  }
}

.pointsCardTitle {
  @include font-24-header;
  font-family: $font-family-medium;
  color: var(--color-content-primary);
  margin-bottom: set-calc(20);

  @include set-media(md) {
    margin-bottom: set-calc(16);
  }
}

.pointsCardText {
  @include font-18-ui;
  color: var(--color-content-paragraph-primary);
}
