@import 'styles/index';

.wrapper {
  display: none;

  @include set-media(md) {
    position: fixed;

    bottom: set-calc(24);
    left: set-calc(8);
    right: set-calc(8);
    z-index: 600;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.btnLink {
  @include btn;
  @include button-56;
  @include button-primary;

  width: 100%;
  box-shadow: $shadow-small-default;
}

.btnWrapperLeft {
  display: flex;
  gap: set-calc(6);
}

.btnWrapperRight {
  display: flex;
  gap: set-calc(6);
  margin-left: auto;
}

.menuBtn {
  @include icon-size(24);

  display: flex;
  justify-content: center;

  padding: set-calc(15) set-calc(15);
  width: set-calc(54);
  height: set-calc(54);
  opacity: 1;

  color: var(--color-content-primary);
  background-color: var(--color-accent-promo-background);
  border-radius: 50%;

  box-shadow: $shadow-small-default;
}