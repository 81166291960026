@import 'styles/index';

.contentExpander~* {
  @include contentExpander;
}

.dialog {
  margin: set-calc(40) 0;

  @include set-media(md) {
    margin: set-calc(40) 0;
  }
}

.messagesStack {
  position: relative;
  display: flex;
  flex-direction: row;
  margin: set-calc(40) 0 set-calc(20);

  &:first-child {
    margin-top: 0;
  }
}

.messagesStack:not(.messagesStack_SideRight) + .messagesStack {

  margin-top: set-calc(20);

  .messageAuthor {
    display: none;
  }
}


.messagesStack_SideRight {
  flex-direction: row-reverse;

  .messagesStack_Content {
    align-items: flex-end;
  } 

  .bubble {
    background: var(--color-content-background-primary);
  }

  .messageAuthor {
    right: 0;
    left: unset;
  }

  .messageText {
    color: var(--color-content-inverted-primary);
  }
}

.messagesStack_Content {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 0;
  max-width: 100%;
}

.message {
  position: relative;
  display: flex;
  align-items: flex-end;
  max-width: 70%;

  @include set-media(md) {
    max-width: 90%;
  }
}

.bubble {
  position: relative;

  display: flex;
  align-items: center;

  padding: set-calc(10) set-calc(16);
  border-radius: set-calc($border-radius-16);
  background: var(--color-background-glassy-b);

  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
}

.messageAuthor {
  position: absolute;
  bottom: calc(100% + 2px);
  left: 0;

  white-space: nowrap;
  color: var(--color-content-paragraph-secondary);
  font-family: $font-family-article;
  @include set-typography(16, 28, 500);
}

.messageText {
  @include set-typography(18, 26, 500);
  font-family: $font-family-article;
  color: var(--color-content-primary);

  word-break: break-word;
}

.btnWrapper {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  margin: set-calc(40) 0 set-calc(20);
}

.button {
  @include btn;
  @include button-56;
  @include button-3d($color: var(--color-accent-promo-background),  $colorActive: var(--color-accent-promo-background));
  justify-content: space-between;

  gap: set-calc(20);
  width: 100%;
  height: max-content;

  padding: set-calc(10) set-calc(16);
  padding-left: set-calc(32);
  background: transparent;

  @include set-typography(18, 24, 500);
  color: var(--color-content-primary);
  font-family: $font-family-article;
  text-align: start;

  .icon {
    background-color: transparent;
    color: $color-palette-black-200;
  }

  &:hover {
    .icon {
      background-color: var(--color-accent-promo-background);
      color: var(--color-content-primary);
    }
  }

  @include set-media(md) {
    width: 100%;
    padding-left: set-calc(20);

    @include set-typography(18, 24, 500);

    .icon {
      background-color: var(--color-accent-promo-background);
      color: var(--color-content-primary);
    }
  }
}

.icon {
  @include btn;
  @include icon-size(40);

  padding: set-calc(4);

  border-radius: 50%;
}
