//screen
.isMobile {
  @include set-media-min(md) {
    display: none;
  }
}

.isDesktop {
  @include set-media(md) {
    display: none!important;
  }
}

//links
.inline-link {
  @include inline-link;
}

.s-hoverable {
  @include s-hoverable;
}

.key-link {
  @include key-link;
}

//fonts
@mixin font-10-ui {
  @include set-typography(10, 10, 500);
} 

@mixin font-12-ui {
  @include set-typography(12, 12, 500);
} 

@mixin font-14-ui {
  @include set-typography(14, 14, 500);
} 

@mixin font-16-ui {
  @include set-typography(16, 16, 500);

  @include set-media(md) {
    @include set-typography(14, 14, 500)
  }
}

@mixin font-18-ui {
  @include set-typography(18, 18, 500);

  @include set-media(md) {
    @include set-typography(16, 16, 500)
  }
}

@mixin font-20-ui {
  @include set-typography(20, 20, 500);

  @include set-media(md) {
    @include set-typography(18, 18, 500);
  }
}

@mixin font-24-ui {
  @include set-typography(24, 24, 500);

  @include set-media(md) {
    @include set-typography(20, 20, 500);
  }
}

@mixin font-14-paragraph {
  @include set-typography(14, 20, 500);
}

@mixin font-16-paragraph {
  @include set-typography(16, 26, 500);
}

@mixin font-18-paragraph {
  @include set-typography(18, 28, 500);

  @include set-media(md) {
    @include set-typography(16, 24, 500)
  }
}

@mixin font-20-paragraph {
  @include set-typography(20, 34, 500);

  @include set-media(md) {
    @include set-typography(16, 24, 500);
  }
}

@mixin font-18-header {
  @include set-typography(18, 24, 500);

  @include set-media(md) {
    @include set-typography(16, 20, 500);
  }
}

@mixin font-24-header {
  @include set-typography(24, 32, 500);

  @include set-media(md) {
    @include set-typography(20, 26, 500);
  }
}

@mixin font-28-header {
  @include set-typography(28, 32, 500);

  @include set-media(md) {
    @include set-typography(24, 28, 500);
  }
}

@mixin font-32-header  {
  @include set-typography(32, 34, 500);

  @include set-media(md) {
    @include set-typography(24, 30, 500);
  }
}

@mixin font-36-header  {
  @include set-typography(36, 38, 500);

  @include set-media(md) {
    @include set-typography(32, 36, 500);
  }
}

@mixin font-40-header {
  @include set-typography(40, 46, 500);

  @include set-media(md) {
    @include set-typography(32, 36, 500);
  }
}

@mixin font-56-header  {
  @include set-typography(56, 60, 500);

  @include set-media(md) {
    @include set-typography(36, 42, 500);
  }
}

@mixin font-60-header  {
  @include set-typography(60, 64, 500);

  @include set-media(md) {
    @include set-typography(40, 44, 500);
  }
}

@mixin font-20-article {
  @include set-typography(20, 34, 500);

  @include set-media(md) {
    @include set-typography(18, 30, 500);
  }
}

@mixin font-26-article {
  @include set-typography(26, 28, 500);

  @include set-media(md) {
    @include set-typography(22, 24, 500);
  }
}

//color
.color-primary-color {
  color: var(--primary-color);
}

.bc-accent-promo-background {
  position: relative;

  &::before {
    position: absolute;
    content: "";

    bottom: 0;
    left: -3px;
    z-index: -1;

    width: calc(100% + 6px);
    height: 50%;

    border-radius: set-calc(10);
    background-color: var(--color-accent-promo-background);

    @include set-media(md) {
      height: 40%;
    }
  }
}

.bold {
  font-family: $font-family-article-bold;
}