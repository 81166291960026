@import 'styles/index';

.header {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex: 1 0 0;

  margin-top: set-calc(32);
  padding: set-calc(56);
  border-radius: set-calc(40);
  background-color: $color-blue;

  gap: set-calc(60);

  @include set-media(md) {
    height: set-calc(750);

    margin-top: 0;
    padding: set-calc(24);
  }
}

.content {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  max-width: set-calc(666);
}

.tagsBlock {
  display: flex;
  padding-bottom: set-calc(16);
  gap: set-calc(4);

  @include set-media(md) {
    padding-bottom: set-calc(8);
  }
}

.tag {
  @include bage;
  @include bage-outline;
  @include bage-40;

  border-color: $color-background-glassy-d-dark;
  color: $color-content-primary-dark;
}

.title {
  margin-bottom: set-calc(28);

  @include font-56-header;
  font-family: $font-family-medium;
  color: $color-content-primary-dark;

  @include set-media(md) {
    margin-bottom: set-calc(20);
  }
}

.description {
  @include font-18-paragraph;
  color: $color-content-primary-dark;

  max-width: set-calc(506);

}

.actions {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: set-calc(16);

  z-index: 100;

  @include set-media(md) {
    width: 100%;
    gap: set-calc(8);
  }
}

.actions__btn {
  @include btn;
  @include button-special($color-content-primary-light, $color-yellow);
  @include button-56;

  @include font-18-ui;

  @include set-media(md) {
    width: 100%;
  }
}

.actions__note {
  display: flex;
  justify-content: center;

  @include font-14-ui;
  color: $color-content-primary-dark;
  text-align: center;
}

.image {
  position: absolute;
  right: 0;
  bottom: 0;

  width: set-calc(1268/2);
  height: set-calc(982/2);

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  @include set-media(md) {
    width: set-calc(796/2);
    height: set-calc(818/2);
  }
}