@import 'styles/index';

._ {
  display: flex;
  width: set-calc(700);
  margin-bottom: set-calc(30);

  @include set-media(md) {
    width: 100%;
  }
}

.tabItem {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 1 auto;
  box-sizing: border-box;

  @include font-20-paragraph;
  color: var(--color-content-secondary);

  width: 50%;
  margin-right: set-calc(2);
  padding-bottom: set-calc(8);
}

.active {
  cursor: default;
  color: var(--color-content-primary);
  border-bottom: set-calc(2.5) solid var(--color-content-primary);
}

.tabItem:not(.active)::after {
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  height: set-calc(2.5);
  content: "";
  transition: transform .2s 0s ease-in-out;
  transform: scaleX(0);
  background-color:  var(--color-content-primary);
}

.tabItem:hover {
  transition: color .3s;
  color: var(--color-content-primary);
}

.tabItem:not(.active):hover::after {
  transform: scaleX(1);
}