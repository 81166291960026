@import 'styles/index';

.wrapper {
  display: block;
  @include is-beige;

  background-color: transparent;
}

.title {
  @include font-56-header;
  font-family: $font-family-bold;
  
  padding-bottom: set-calc(32);
  padding-left: set-calc(16);

  @include set-media(md) {
    padding-bottom: set-calc(16);
    padding-left: set-calc(8);
    padding-right: set-calc(8);
  }
}

.list {
  @include is-grid-4
}